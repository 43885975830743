<template>
  <div class="modal fade" id="exampleModal"  data-backdrop="false" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body">
          <div class="text-right">
            <button @click="removeNotification" type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <br>
          <div v-for="notification in notifications" :key="notification.id">
            {{ notification.description }}
            <hr>
          </div>

          <div class="text-center">
            <button type="button" @click="removeNotification" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "PopupNotifications",
  data: function () {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      isLoading: true,
      pop_notifications_closed: localStorage.getItem('pop_notifications_closed') || "false",
      notifications: JSON.parse(localStorage.getItem('pop_notifications')) || []
    };
  },
  methods: {
    removeNotification(){
      // localStorage.setItem('pop_notifications', null)
      localStorage.setItem('pop_notifications_closed', "true")
    },
    showModal() {
     if (this.pop_notifications_closed === 'false' && this.notifications.length > 0)
         // eslint-disable-next-line no-undef
       $("#exampleModal").modal('show');
    },
    getAllNotifications: function () {
        this.isLoading = true;
        axios.get("notifications/popup")
            .then(result => {
              this.isLoading = false;
              this.notifications = result.data

              const stringData = JSON.stringify(result.data)
              const existingData = localStorage.getItem('pop_notifications')
              const checkIfSameData = stringData === existingData

              if (checkIfSameData === false){
                localStorage.setItem('pop_notifications_closed', "false")
                this.pop_notifications_closed = "false"
              }
              if (checkIfSameData === false || this.pop_notifications_closed === 'false'){
                this.showModal()
              }

              localStorage.setItem('pop_notifications', stringData)
              this.notifications = result.data;

            }, error => {
              this.isLoading = false;
              localStorage.setItem('pop_notifications_closed', "false")
              console.log(error.response);
            });
    },
  },
  mounted() {
    this.getAllNotifications()
  },

};
</script>

<style lang="scss" scoped>
@media (min-width: 576px){
  .modal-dialog {
    max-width: 500px;
    margin-top: 17.75rem !important;
    margin-bottom: 17.75rem !important;
    margin-left: auto !important;
  }
}
@media only screen and (max-width: 600px) {
  .modal-dialog {
    margin: 17.75rem 1rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 500px;
    margin-top: 17.75rem !important;
    margin-bottom: 17.75rem !important;
    margin-left: 45.75rem !important;
  }
}
.modal-footer,.modal-header,.modal-body{
  background-color: #e7615a !important;
  color: #FFFFFF;
}
</style>
