<template>
  <div class="entry-content">
    <div class="container-fluid mt--7">
    </div>

  </div>
</template>
<script>
  export default {
    components: {

    },
    data() {
      return {

      };
    },
    methods: {

    },
    mounted() {

    }
  };
</script>
<style></style>