<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="99" viewBox="0 0 92 99">
    <g fill="none" fill-rule="evenodd">
        <g>
            <g>
                <path d="M0 0H170V170H0z" transform="translate(-279 -1441) translate(241 1413) matrix(-1 0 0 1 170 0)"/>
                <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" d="M38.06 77.705L75.532 120.522 130.037 120.522 130.037 34.888 75.532 34.888z" transform="translate(-279 -1441) translate(241 1413) rotate(90 84.049 77.705)"/>
                <path stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" d="M72.313 122.425L72.313 90.075 96.418 90.075 96.418 122.425" transform="translate(-279 -1441) translate(241 1413)"/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'kitchenHome'
    }
</script>

<style lang="scss" scoped>
  div {
    width: 100%;
  }
</style>