<template>
  <div class="bg-white">
    <HeaderMenu />
    <div class="container-fluid container h-full">
      <!-- WishList Section -->
      <div class="col wishlist-title">
        <h1>Wish List</h1>
      </div>
      <div
        v-if="loading"
        class="w-100 d-flex justify-content-center align-items-center"
        style="height: 50vh"
      >
        <div class="loader" style="height: 75px; width: 75px"></div>
      </div>
      <div v-else>
        <div v-if="!products.length">
          <WishListEmpty />
        </div>
        <div v-else class="row d-flex mx-auto py-4">
          <div
            v-for="(product,index) in products"
            :key="product.Id"
            :product="product"
            class="mb-6 col-3"
          >

            <div class="d-flex flex-column align-center"
                  v-if="product.product">

                <div class="image-container">
                  <router-link :to="'/product/' + product.id" style="color: #424242 !important;">
                  <img
                      class="rounded-image"
                      :src="appURL + product.product.image"
                      @error="imageError"
                      alt=""
                      style="width: 100%; height: 250px;"
                  />
                  </router-link>

                  <!-- Remove from wishlist -->
                  <button
                      class="text-dark shadow-sm btn position-absolute"
                      @click="removeWishList(product.id,index)"
                  >
                    <div class="d-flex justify-content-center align-items-center" style="width:32px; height:32px;">
                      <div v-if="product.deleting" class="loader"></div>
                      <img v-else src="../../public/img/icons/info-close.svg" />
                    </div>
                  </button>
                </div>


              <div class="w-100"></div>
              <p class="product-title font-weight-bolder text-center mb-1 mt-3">
                {{ product.product.name }}
              </p>

              <div class="product-price font-weight-normal text-center mb-2">
                € {{ product.product.price }}
              </div>

              <router-link
                :to="'/product/' + product.product.id"
              >
                <div class="btn btn-buy w-100">BUY</div>
              </router-link>
            </div>
          </div>
        </div>
          <div class="row d-flex flex-column justify-content-center align-items-center empty-list py-2 " v-if="products.length">
          <router-link to="/products">
            <button class="btn btn-buy mt-4">Back to Shop</button>
          </router-link>
      </div>
      </div>
    </div>

    <!-- Similar Products Section -->
    <div v-if="relatedProducts.length" class="container-fluid container h-full">
      <div class="container-fluid">
        <div class="row">
          <div class="col align-items-end  wish-title font-weight-bolder  mb-4">
            <hr />
            <div class="title-secondary mt-5">Popular Products</div>
          </div>
        </div>
        <div class="row pb-2 products-wrapper">
          <div
              class="col-3 products bg-white mx-0 my-4 py-4"
              v-for="product in relatedProducts"
              :key="product.id"
          >
            <div class="add-wishlist" @click="addToWishlist(product.id,product)">
              <div v-if="product.updatingWishlist">
                <div class="loader"></div>
              </div>
              <div v-else>
                <i v-if="product.wishlist && !product.deleting" class="fas fa-heart "></i>
                <i v-else class="far fa-heart "></i>
              </div>
            </div>
            <router-link :to="'/product/' + product.id" style="color: #424242 !important;">
              <div class="bg-black text-center resource-image-block mt-3" style="position: relative; top: 0; left: 0;">
                <img
                    class="popular-product-image rounded-image"
                    v-if="product.image"
                    :src="appURL + product.image"
                    alt="product image --"
                    @error="imageError"
                    style="width: 90%; height: 250px;"
                />
                <img v-else src=""
                      class="my-3" alt="default image" style="width: 200px; height: 200px; padding-left:50px !important;">
              </div>
              <p class="text-center resource-large-text m-0 p-0 mt-3" style="  font-size: 16px !important; font-weight: bold !important;">
                {{ product.name }}
              </p>
              <p class="text-center resource-large-text m-0 p-0 mt-2 mb-2">
                € {{ product.price }}
              </p>
              <router-link
                :to="'/product/' + product.id"
              >
                <div class="btn btn-buy w-100">BUY</div>
              </router-link>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderMenu from "../views/HeaderMenu";
import WishListEmpty from "../views/WishListEmpty";
import axios from "axios";
import Footer from "../components/Footer";

export default {
  components: {
    HeaderMenu,
    WishListEmpty,
    Footer,
  },
  name: "Wishlist",
  data() {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      wishList: [],
      products: [],
      relatedProducts: [],
      loading: true,
    };
  },
  mounted() {
    this.getWishList();
    this.getProducts();
  },

  beforeCreate() {
    localStorage.setItem('search', '')
  },
  methods: {
    getWishList: function () {
      // const token = localStorage.getItem("access_token");
      // if(token){
      //   let url = `user/wishlist?access_token=${localStorage.getItem(
      //     "access_token"
      //   )}`;
      //   axios({
      //     method: "GET",
      //     url: url,
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //   }).then(
      //     (result) => {
      //       if (result) {
      //         this.products = result.data.wishlist_items;
      //         this.loading = false;
      //         this.products.forEach((product)=>{
      //           product.deleting = false;
      //         })
      //       }
      //     },
      //     (error) => {
      //       console.log(error);
      //       this.loading = false;
      //     }
      //   );
      // }else{
          let url = `offline/wishlist`;
          let wishlist_items = localStorage.getItem("wishlist_items");
          if(wishlist_items){
            wishlist_items = JSON.parse(wishlist_items);
          }
          else{
            wishlist_items = [];
          }
      this.loading = false;
          axios({
            method: "POST",
            url: url,
            data: {
              wishlist_items: wishlist_items,
            },
            headers: {
              "content-type": "application/json",
            },
          }).then(
              (result) => {
                if (result) {
                  this.products = result.data.wishlist_items;
                  this.loading = false;
                }
              },
              (error) => {
                console.log(error);
                this.loading = false;
              }
          );
      // }
    },

    getProducts: function () {
      // let url = `user/popular-products?access_token=${localStorage.getItem('access_token')}`;
      let url = `user/products/all?access_token=${localStorage.getItem('access_token')}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then((result) => {
            if (result) {
              console.log(result.data.products);
              // this.products = result.data.products.slice(0,4);
              this.relatedProducts = result.data.products.data;
              this.relatedProducts = result.data.products.data.slice(0, 4);
              let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items")) ;
              if(wishlist_items != null){
                this.relatedProducts.forEach(product => {
                  product.updatingWishlist = false;
                  wishlist_items.includes(product.id)?
                  product.wishlist = true:''
                });
              }
            }
          },
      ).catch((error) => {
        console.log(error);
      });
    },
    removeWishList: function (id,index) {
      const token = localStorage.getItem("access_token");
      this.$set(this.products[index], 'deleting', true);


      this.$forceUpdate();
      if(token){
        let url = `user/wishlist/delete?access_token=${localStorage.getItem(
          "access_token"
        )}&product_id=${id}`;
        axios({
          method: "DELETE",
          url: url,
          headers: {
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            if (result) {
              this.getWishList();
              this.removeWishListLocally(id,index)
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }else{
        this.removeWishListLocally(id,index)
      }
         // eslint-disable-next-line no-undef
         Fire.$emit('added_to_wishlist');
    },
    removeWishListLocally: function (id, index){
      let wishlist_items = localStorage.getItem("wishlist_items");
      if(wishlist_items){
        wishlist_items = JSON.parse(wishlist_items);
      }
      else{
        wishlist_items = [];
      }

      let storage_index = 0

      wishlist_items.forEach((item,itemIndex)=>{
        if(item.id == id){
          storage_index = itemIndex;
        }
      })
      wishlist_items = [
        ...wishlist_items.slice(0, storage_index),
        ...wishlist_items.slice(storage_index + 1)
      ]
      this.$noty.success('Item successfully removed from wishlist',{ closeWith : ['button']})
      localStorage.setItem("wishlist_items",JSON.stringify(wishlist_items));

      setTimeout(()=>{
        this.products.splice(index, 1);
      }, 500);

      this.getProducts();
    },
    removeItem: function(arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },
    imageError: function(e){
      e.target.src = "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"
    },
    addWishListLocally: function (product){
      let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items")) ;
      if(wishlist_items == null){
        wishlist_items = [];
      }

      if(!wishlist_items.includes(product.id)){
        wishlist_items.push(product.id);
        localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
        this.$noty.success('Item successfully added to wishlist',{ closeWith : ['button']})
        setTimeout(()=>{
          product.wishlist = true;
          product.updatingWishlist = false;
          this.getProducts();
        }, 500);

        this.getWishList();
      }
      else{
        wishlist_items = this.removeItem(wishlist_items, product.id);
        this.$noty.success('Item successfully removed from wishlist',{ closeWith : ['button']})

        localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
        setTimeout(()=>{
          product.wishlist = false;
          product.updatingWishlist = false;
          this.getProducts();
        }, 500);
        this.getWishList();
      }
    },
    addToWishlist: function(id,product){
      product.updatingWishlist = true;
      this.relatedProducts = JSON.parse( JSON.stringify( this.relatedProducts ) );

      const token = localStorage.getItem("access_token");
      if(!token){
       this.addWishListLocally(product)
      }
      else{
        let url = '';
        let method = '';
        if(!product.wishlist){
          url = `user/wishlist/add?access_token=${localStorage.getItem('access_token')}&product_id=${id}`;
          method = 'POST';
        }
        else{
         url = `user/wishlist/delete?access_token=${localStorage.getItem("access_token")}&product_id=${id}`;
         method = 'DELETE';
        }
        axios({
          method: method,
          url: url,
          headers: {
            "content-type": "application/json",
          },
        }).then(
          (result) => {
            this.addWishListLocally(product)
            this.$logEvent(this.$analytics,"add_to_wishlist");
            this.$logEvent(this.$analytics,"user_engagement");
            result;

          },
          (error) => {
            if (error.response.status == 401) {
              this.$router.push({path: "/login"})
            }
            console.log(error);
          }
        );
      }
      this.getProducts();
      this.getWishList();
      // eslint-disable-next-line no-undef
      Fire.$emit('added_to_wishlist');
    }
  },

};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1300px;
}

.fa-heart{
  color: black;
}

.rounded-image{
  border-radius: 8px;
}

.product-title{
  font-family: "Heebo";
  font-size: 18px;
  font-weight: bold;
  color: #424242;
}

.product-price{
  font-family: "Heebo";
  font-size: 16px;
  font-weight: normal;
  color: #424242;
}

.wishlist-title {
  padding-top: 53px;

  h1 {
    font-family: "Heebo";
    font-size: 24px;
    font-weight: bold;
    color: #424242;
  }
}

.title-main{
  font-family: "Heebo";
  font-size: 24px;
  font-weight: bold;
  color: #424242;
}

.image-container button {
  right: 30px;
  top: 5%;
  border-radius: 4px;
  font-size: 12px;
  padding: 0;
}

.discount-price {
  color: #e7615a;
}

.wish-title {
  height: 116px;
  font-size: 24px;
}
.horizontal-line {
  width: 10%;
}

.no-stock img,
.no-stock .title,
.no-stock .price,
.no-stock i {
  opacity: 0.34;
}

.btn-buy {
  width: 240px;
  background-color: #e7615a;
  color: white;
}
.add-wishlist{
    right: 30px;
    top: 55px;
}

.title-secondary{
  font-size: 18px;
  font-weight: bold;
  color: #424242;
}
</style>
