<template>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card bg-white shadow border-0">
                    <div class="card-body px-lg-5 py-lg-5 d-flex flex-row justify-content-center">
                        <form role="form" class="reset-form">
                            <h1 class="">Reset Password</h1>
                            <p class="forgot mb-4">We will send you an email to reset your password.</p>
                            <div class="email-input-group form-label-group mx-auto ">
                              <input
                                name="email"
                                type="email"
                                id="inputEmail"
                                class="form-control border-silver "
                                v-validate="'required|email'"
                                placeholder=" "
                                required=""
                                v-model="model.email"
                                autofocus=""
                                @keyup.enter="resetPassword"
                                :style="[errors.has('email') ? {'color': '#e74c3c !important','border': 'solid 1px #e74c3c !important'} : {}]"
                              />
                            <label for="inputEmail">Email</label>
                            </div>
                            <span v-show="errors.has('email')" class="help is-danger fixed-width">{{ errors.first('email') }}</span>
                            <div class="text-danger text-center m-0 p-0">{{errorMessage}}</div>
                            <div class="text-center mt-4 mb-4">
                                <div class="btn btn-shop text-white text-nowrap p-2" @click="resetPassword">
                                  <div class="d-flex flex-row justify-content-center" v-if="loading"><div class="loader"></div></div>
                                  <div v-else>RESET PASSWORD</div>
                                </div>
                                <div style="text-decoration: underline; text-underline-offset: 1.5px;" class="forgot mt-4">
                                    <router-link to="/login" style="color:black;">CANCEL</router-link>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import axios from 'axios';
  export default {
    data() {
      return {
        model: {
          email: '',
        },
        loading: false,
        errorMessage: "",
      }
    },
    methods: {
      resetPassword: function () {
        this.$validator.validateAll().then((result) => {
          if (result) {
                  
        this.loading = true;
        let url = `user/forget-password`;
        axios({
          method: "POST",
          url: url,
          data:{ email: this.model.email},
          headers: {
            "content-type": "application/json",
          },
        }).then(
        (result) => {
          if (result) {
            this.$noty.success('Password reset instructions has been sent to your email.',{ closeWith : ['button']});
            this.$router.push('/login');
            this.loading = false;
          }
        },(error) => {
            console.log(error);
            this.loading = false;
            this.errorMessage = error.response.data.message;
        });
        }
       }).catch(() => {});
      }
    }
  }
</script>
<style scoped>
.reset-form{
  max-width: 312px;
}

.forgot{
  font-family: Heebo;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.no-account{
  width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
.sign-up{
  color: #e7615a;
}
.card {
  min-width: 560px;
  border-radius: 16px;
}

.card-body {
  padding: 15% !important;
}

.btn-shop {
  margin-top: 7px;
  background-color: #e7615a;
  border-color: #e7615a;
  width: 300px;
  height: 40px;
  object-fit: contain;
  font-size: 14px;
  font-weight: bold;
}

.form-label-group {
  width: 300px !important;
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 2.125rem;
  padding: 0.75rem;
}



.form-label-group label {
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #b9c3bc;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.75rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:-webkit-autofill ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

</style>
