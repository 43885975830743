<template>
  <div class="mx-6">
    <div class="mt-5 login" v-if="!loggedIn">
      Already have an account?
      <span class="underline">
        <router-link to="/login" style="color: #457b80 !important"
        >Login</router-link
        >
      </span>
      <div class="row">
        <div class="col-5"><hr /></div>
        <div class="col-2 mt-3 pt-1">Or</div>
        <div class="col-5"><hr /></div>
      </div>
    </div>
    <div class="row">
      <div class="title mt-5 mb-4">Shipping Details</div>
      <div class="mt-5 mb-4 offset-4" v-if="!loggedIn">
        <input type="checkbox" v-model="createAccount" class="mx-2" />
        Create an account
      </div>
    </div>
    <div class="">
      <div class="shipping-form">
        <div class="form-group">
          <input name="name"
                 type="text"
                 class="form-control"
                 alternative=""
                 v-validate="'required|min:3'"
                 placeholder="Full Name" pattern="[a-zA-Z]+"
                 @change="form_errors.shipping_full_name = false"
                 v-model="order.shipping_full_name"
                 v-bind:class="{ 'is-invalid': form_errors.shipping_full_name }"
          />
          <span class="help is-danger">{{ errors.first('name') }}</span>
        </div>
        <div class="form-group">
          <input
              class="form-control"
              alternative=""
              name="company_name"
              @change="form_errors.shipping_company_name = false"
              placeholder="Company Name (optional)"
              v-model="order.shipping_company_name"
          />
        </div>
        <div class="form-group mt-4">
          <input
              name="street_address"
              v-validate="'required|min:3'"
              class="form-control"
              alternative=""
              @change="form_errors.shipping_address = false"
              placeholder="Street Address"
              v-model="order.shipping_address"
              v-bind:class="{ 'is-invalid': form_errors.shipping_address }"
          />
          <span class="help is-danger">{{ errors.first('street_address') }}</span>
        </div>
        <div class="d-flex flex-row">
          <div class="form-group w-50 pr-3">
            <input
                name="city"
                v-validate="'required|min:3'"
                pattern="[a-zA-Z]+"
                class="form-control"
                alternative=""
                @change="form_errors.shipping_city = false"
                placeholder="City"
                v-model="order.shipping_city"
                v-bind:class="{ 'is-invalid': form_errors.shipping_city }"
            />
            <span class="help is-danger">{{ errors.first('city') }}</span>
          </div>
          <div class="form-group w-50 pl-3">
            <input
                name="zip_code"
                v-validate="'required|min:2'"
                class="form-control"
                alternative=""
                placeholder="Zip Code"
                @change="form_errors.shipping_postcode = false"
                v-model="order.shipping_postcode"
                v-bind:class="{ 'is-invalid': form_errors.shipping_postcode }"
            />
            <span class="help is-danger">{{ errors.first('zip_code') }}</span>
          </div>
        </div>
        <div class="form-group">
          <input
              name="country"
              v-validate="'required|min:2'"
              pattern="[a-zA-Z]+"
              class="form-control"
              alternative=""
              placeholder="Country"
              @change="form_errors.shipping_country = false"
              v-model="order.shipping_country"
              v-bind:class="{ 'is-invalid': form_errors.shipping_country }"
          />
          <span class="help is-danger">{{ errors.first('country') }}</span>
        </div>
        <div class="form-group">
          <input
              name="email"
              v-validate="'required|email'"
              class="form-control"
              alternative=""
              @change="form_errors.shipping_email = false"
              placeholder="Email"
              v-model="order.shipping_email"
              v-bind:class="{ 'is-invalid': form_errors.shipping_email }"
          />
          <span class="help is-danger">{{ errors.first('email') }}</span>
        </div>
        <div class="form-group">
          <input
              type="number"
              name="phone_number"
              v-validate="'required|min:8'"
              class="form-control"
              alternative=""
              placeholder="Phone"
              @change="form_errors.shipping_phone = false"
              v-bind:class="{ 'is-invalid': form_errors.shipping_phone }"
              v-model="order.shipping_phone"
          />
          <span class="help is-danger">{{ errors.first('phone_number') }}</span>
        </div>
      </div>
      <div class="my-4">
        <input type="checkbox" class="mt-3 mr-3" v-model="billing" />
        Use a different billing address
      </div>
      <div class="shipping-form" v-if="billing">
        <div class="form-group">
          <input
              class="form-control"
              alternative=""
              placeholder="Full Name"
              @change="form_errors.billing_full_name = false"
              v-bind:class="{ 'is-invalid': form_errors.billing_full_name }"
              v-model="order.billing_full_name"
          />
        </div>
        <div class="form-group mt-4">
          <input
              class="form-control"
              alternative=""
              placeholder="Street Address"
              @change="form_errors.billing_address = false"
              v-bind:class="{ 'is-invalid': form_errors.billing_address }"
              v-model="order.billing_address"
          />
        </div>
        <div class="d-flex flex-row">
          <div class="form-group w-50 pr-3">
            <input
                class="form-control"
                alternative=""
                placeholder="City"
                @change="form_errors.billing_city = false"
                v-model="order.billing_city"
                v-bind:class="{ 'is-invalid': form_errors.billing_city }"
            />
          </div>
          <div class="form-group w-50 pl-3">
            <input
                class="form-control"
                alternative=""
                placeholder="Zip Code"
                @change="form_errors.billing_postcode = false"
                v-model="order.billing_postcode"
                v-bind:class="{ 'is-invalid': form_errors.billing_postcode }"
            />
          </div>
        </div>
        <div class="form-group">
          <input
              class="form-control"
              alternative=""
              placeholder="Country"
              @change="form_errors.billing_country = false"
              v-bind:class="{ 'is-invalid': form_errors.billing_country }"
              v-model="order.billing_country"
          />
        </div>
        <div class="form-group">
          <input
              class="form-control"
              alternative=""
              placeholder="Email"
              @change="form_errors.billing_email = false"
              v-bind:class="{ 'is-invalid': form_errors.billing_email }"
              v-model="order.billing_email"
          />
        </div>
        <div class="form-group">
          <input
              class="form-control"
              alternative=""
              placeholder="Phone"
              @change="form_errors.billing_phone = false"
              v-bind:class="{ 'is-invalid': form_errors.billing_phone }"
              v-model="order.billing_phone"
          />
        </div>
      </div>
    </div>
    <div class="mt-5 mb-4">
      <div style="width: 480px; height: 1px; background-color: #b9c3bc"></div>
      <div class="summary-title mt-5 mb-4">Shipping Method</div>
      <div>
        <div class="row" v-for="method in shippingMethod" :key="method.id">
          <input
              style="margin-top: 5px"
              v-on:change="emit(method.shipping_price)"
              :checked="method.method === 'Priority Shipping'"
              type="radio"
              name="shippingMethodPrice"
          />
          <div class="col-8">
            <div class="summary-heading">
              {{ method.method }}, {{ method.service }}
            </div>
            <div class="summary-details">
              {{ method.time }}
            </div>
          </div>
          <div class="summary-heading-normal">
            € {{ method.shipping_price }}
          </div>
        </div>
      </div>
    </div>

    <div class="error-message text-danger">
      {{ errorMessage }}
    </div>
    <div class="">
      <div
          class="
          mt-6
          mb-4
          d-flex
          flex-row
          justify-content-between
          align-items-center
        "
      >
        <router-link to="/products" class="back-to-shop"
        >&lt; <span class="underline">Back to shop</span>
        </router-link>
        <div
            class="btn btn-red"
            @click="addShippingBillingDetails"
            v-if="
            order.shipping_full_name &&
            order.shipping_address &&
            order.shipping_postcode &&
            order.shipping_city &&
            order.shipping_country &&
            order.shipping_email &&
            order.shipping_phone &&
            (!billing ||
              (order.billing_full_name &&
                order.billing_address &&
                order.billing_postcode &&
                order.billing_city &&
                order.billing_country &&
                order.billing_email &&
                order.billing_phone))
          "
        >
          CONTINUE
        </div>
        <div v-else class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <div @click="validate" class="btn btn-red">CONTINUE</div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div>
                  <small class="text-muted"
                  >Please fill out all the required fields to continue</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import router from "../router";
import moment from "moment";

export default {
  name: "checkout-shipping",
  data: function () {
    return {
      createAccount  : true,
      order: {
        same_billing_details: false,
        shipping_full_name: "",
        shipping_email: "",
        shipping_phone: "",
        shipping_address: "",
        shipping_city: "",
        shipping_postcode: "",
        shipping_country: "",
      },
      form_errors: {
        shipping_full_name: false,
        shipping_email: false,
        shipping_phone: false,
        shipping_address: false,
        shipping_city: false,
        shipping_postcode: false,
        shipping_country: false,
        billing_full_name: false,
        billing_email: false,
        billing_phone: false,
        billing_address: false,
        billing_city: false,
        billing_postcode: false,
        billing_country: false,
      },
      form_valid : true,
      shippingMethod: {},
      errorMessage: "",
      billing: false,
      shipping_charges: 50,
      shipping_price: 0,
      products: [],
      shipping_methods: {},
    };
  },
  methods: {
    validate: function () {
      if (!this.order.shipping_full_name) {
        this.form_errors.shipping_full_name = true;
      }
      if (!this.order.shipping_email) {
        this.form_errors.shipping_email = true;
      }
      if (!this.order.shipping_phone) {
        this.form_errors.shipping_phone = true;
      }
      if (!this.order.shipping_address) {
        this.form_errors.shipping_address = true;
      }
      if (!this.order.shipping_city) {
        this.form_errors.shipping_city = true;
      }
      if (!this.order.shipping_postcode) {
        this.form_errors.shipping_postcode = true;
      }
      if (!this.order.shipping_country) {
        this.form_errors.shipping_country = true;
      }
      if (!this.order.shipping_full_name) {
        this.form_errors.shipping_full_name = true;
      }
      if (this.billing) {
        if (!this.order.shipping_full_name) {
          this.form_errors.billing_full_name = true;
        }
        if (!this.order.billing_email) {
          this.form_errors.billing_email = true;
        }
        if (!this.order.billing_phone) {
          this.form_errors.billing_phone = true;
        }
        if (!this.order.billing_address) {
          this.form_errors.billing_address = true;
        }
        if (!this.order.billing_city) {
          this.form_errors.billing_city = true;
        }
        if (!this.order.billing_postcode) {
          this.form_errors.billing_postcode = true;
        }
        if (!this.order.billing_country) {
          this.form_errors.billing_country = true;
        }
        if (!this.order.billing_full_name) {
          this.form_errors.billing_full_name = true;
        }
      }
      let is_invalid = false;
      Object.keys(this.form_errors).forEach((key) => {
        console.log('validator erros length',this.$validator.errors.items.length)
        if (!this.form_errors[key] && this.$validator.errors.items.length >= 1) {
          is_invalid = true;
          this.form_valid = false
        }
      });
      if (is_invalid) {
        this.$noty.error("Please fill out all the required fields with the right inputs", {
          timeout: 1500,
          closeWith : ['button']
        });
      }
      console.log('form_valid',this.form_valid)
      return this.form_valid
    },
    isLoggedIn() {
      const tokenExpiry = localStorage.getItem("access_token_expires_at");
      if (!tokenExpiry) {
        return false;
      }

      const tokenExpiryTime = moment(
          localStorage.getItem("access_token_expires_at")
      );
      const today = moment();

      if (today.diff(tokenExpiryTime) < 0) {
        return true;
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("access_token_expires_at");
        localStorage.removeItem("name");
        return false;
      }
    },
    getShippingMethods: function () {
      axios({
        method: "POST",
        url: "user/shipping-price",
        data: {
          products: this.products,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (res) => {
            if (res) {
              const shippingPrice = res.data.shipping_price;
              let url = `user/shipping-methods`;
              axios({
                method: "GET",
                url: url,
                headers: {
                  "content-type": "application/json",
                },
              }).then(
                  (result) => {
                    if (result) {
                      this.shippingMethod = result.data.shipping_methods;
                      //Add shipping price to each method
                      this.shippingMethod.forEach((method) => {
                        if (method.method === "Urgent Shipping") {
                          method.shipping_price = (
                              parseFloat(shippingPrice) * 1.2
                          ).toFixed(2);
                          // localStorage.setItem("shipping", method.shipping_price);
                        } else {
                          method.shipping_price = shippingPrice;
                          localStorage.setItem("shipping", method.shipping_price);
                        }
                      });
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
              );
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    getProfileOrder: function () {
      if (localStorage.getItem("access_token") != null) {
        let url = `user/profile?access_token=${localStorage.getItem(
            "access_token"
        )}`;
        axios({
          method: "GET",
          url: url,
          headers: {
            "content-type": "application/json",
          },
        })
            .then((result) => {
              if (result) {
                this.products = result.data.orders;
                let orderLength = result.data.orders.length;
                orderLength
                    ? (this.order = result.data.orders[orderLength - 1])
                    : "";
              }
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status === 401) {
                this.$router.push("/login");
              }
            });
        this.loading = false;
      }
    },
    getShippingPrices: function () {
      let url = "user/shipping-price";
      axios({
        method: "POST",
        url: url,
        data: {
          products: this.products,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              this.shipping_price = result.data.shipping_price;
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    addShippingBillingDetails: function () {
      this.validate()
      this.order.same_billing_details = !this.billing;
      this.order.shipping_charges = this.shipping_charges;
      this.order.do_signup = (this.createAccount && !this.loggedIn);
      localStorage.setItem("shippingDetails", JSON.stringify(this.order));
      this.$logEvent(this.$analytics,"add_shipping_info");
      this.$logEvent(this.$analytics,"user_engagement");
      if(this.form_valid){
      this.$emit("go-to-payment");
      }
    },
    emit: function(price) {
      if (this.form_valid){
        this.$emit('shipping_price', price)
      }
    },
  },
  mounted(){
    this.products = JSON.parse(localStorage.getItem('cart'));
    try {
      let shippingDetails = JSON.parse(localStorage.getItem("shippingDetails"));
      if(shippingDetails.shipping_full_name){
        this.order = shippingDetails;
      }
    }
        /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    catch (error) {

    }
    this.loggedIn = this.isLoggedIn();
    this.getProfileOrder();
    this.getShippingMethods();
    this.getShippingPrices();
  },
};
</script>


<style lang="scss">
.shipping-form {
  input {
    border-radius: 4px !important;
    box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
    border: solid 1px #b9c3bc !important;
    background-color: #fff !important;

    &::placeholder {
      font-family: "Heebo" !important;
      font-size: 14px !important;
      color: #b9c3bc !important;
    }
  }
}

.back-to-shop {
  font-family: "Heebo" !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #424242 !important;
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.summary-heading {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.summary-heading-normal {
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.summary-details {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.login {
  font-size: 18px;
  line-height: 1.22;
  color: #424242;
  text-align: center;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}
</style>
