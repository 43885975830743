<template>
  <div class="px-6 pt-4">
    <div class="">
      <div class="">
        <div class="">
          <div class="payment-form">
            <div class="mb-3 payment-logos">
              <i class="fa fa-cc-visa mt-2" style="font-size: 32px"></i>
              <i
                class="fa fa-cc-mastercard mt-2 ml-3"
                style="font-size: 32px"
              ></i>
              <i
                class="fa fa-cc-amex mt-2 ml-3"
                style="font-size: 32px"
              ></i>
            </div>
            <div class="">
              <base-input
                alternative=""
                placeholder="Card Number"
                input-classes="form-control"
                v-model="paymentDetails.cardNumber"
                v-on:keyup="changeCardnumber"
                maxlength = "19"
              />
            </div>
            <div class="d-flex flex-row">
              <div class="w-50 pr-3">
                <base-input
                  alternative=""
                  placeholder="MM/YY"
                  input-classes="form-control"
                  v-model="paymentDetails.cardExp"
                  v-on:keyup="changeCardExp"
                  maxlength="5"
                />
              </div>
              <div class="w-50 pl-3">
                <base-input
                  alternative=""
                  placeholder="CVV"
                  input-classes="form-control"
                  v-model="paymentDetails.cardCVV"
                  maxlength = "3"
                />
              </div>
            </div>
            <div class="">
              <base-input
                alternative=""
                placeholder="Card Holder's Name"
                input-classes="form-control"
                v-model="paymentDetails.cardOwnerName"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div
          class="mt-6 d-flex flex-row justify-content-between align-items-center">
          <a class="go-back"
            >&lt; <span class="underline" @click="goToShipping">Back</span>
          </a>
          <div class="btn btn-red" @click="addPaymentDetails" v-if="paymentDetails.cardNumber && paymentDetails.cardExp
          && paymentDetails.cardCVV && paymentDetails.cardOwnerName">CONTINUE</div>
          <div class="btn btn-grey" v-else>CONTINUE</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "checkout-payment",
  data() {
    return {
      shippingDetails: {},
      errorMessage: "",
      paymentDetails: {
        cardNumber: null,
        cardExp: null,
        cardCVV: null,
        cardOwnerName: null,
      },
    };
  },
  methods: {
    goToShipping: function () {
      this.$emit("go-to-shipping");
    },
    addPaymentDetails: function(){
      localStorage.setItem("paymentDetails", JSON.stringify(this.paymentDetails))
      this.$logEvent(this.$analytics,"add_shipping_info");
      this.$logEvent(this.$analytics,"user_engagement");
      this.$router.push("/cart-summary");
    },
    changeCardExp: function(event){
      var input = this.paymentDetails.cardExp;
      if(input !== ""){
        if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
        var values = input.split('/').map(function(v) {
          return v.replace(/\D/g, '')
        });
        if (values[0] !== '0' || values[0] == '00') {
            var num = parseInt(values[0]);
            if (isNaN(num) || num <= 0 || num > 12) num = 1;
            values[0] = num > 1 
                   && num.toString().length == 1 ? '0' + num : num.toString();
        }
        if(event.keyCode === 8){
          if(input.length === 2){
            this.paymentDetails.cardExp = this.paymentDetails.cardExp.substr(0,1);
          }
        } else if(values !== ""){
          var output = values.map(function(v, i) {
            return v.length == 2 && i < 2 ? v + '/' : v;
          });
          this.paymentDetails.cardExp = output.join('').substr(0, 5);
         }
      }
    },
    changeCardnumber: function(event){
      if (this.paymentDetails.cardNumber.length === 4 || this.paymentDetails.cardNumber.length === 9 || this.paymentDetails.cardNumber.length === 14) {
       this.paymentDetails.cardNumber = this.paymentDetails.cardNumber + '-'
      }
      if(event.keyCode === 8){
        if(this.paymentDetails.cardNumber.length === 5){
          this.paymentDetails.cardNumber = this.paymentDetails.cardNumber.substr(0,3);
        }
        if(this.paymentDetails.cardNumber.length === 10){
          this.paymentDetails.cardNumber = this.paymentDetails.cardNumber.substr(0,8);
        }
        if(this.paymentDetails.cardNumber.length === 15){
          this.paymentDetails.cardNumber = this.paymentDetails.cardNumber.substr(0,13);
        }
      } 
    }
  },
  mounted : function(){
    try {
      let existingDetails =  JSON.parse(localStorage.getItem("paymentDetails"));
      console.log(existingDetails);
    if(existingDetails.cardNumber){
      this.paymentDetails.cardNumber = existingDetails.cardNumber;
      this.paymentDetails.cardOwnerName = existingDetails.cardOwnerName;
      this.paymentDetails.cardExp = existingDetails.cardExp;
    }
    } 
     /* eslint no-empty: ["error", { "allowEmptyCatch": true }] */
    catch (error) {
      
    }
    
  }
};
</script>


<style lang="scss">
.go-back {
  cursor: pointer;
}

.payment-form {
  input {
    border-radius: 4px !important;
    box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
    border: solid 1px #b9c3bc !important;
    background-color: #fff !important;

    &::placeholder {
      font-family: "Heebo" !important;
      font-size: 14px !important;
      color: #b9c3bc !important;
    }
  }
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.go-back {
  font-family: "Heebo" !important;
  font-size: 16px !important;
  font-weight: normal !important;
  color: #424242 !important;
}

.payment-logos{
  color:#424242 !important;
}

.btn-grey{
  color:grey;
  box-shadow: none;
}
</style>