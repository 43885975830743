<template>
  <div>
    <HeaderMenu />
    <br>
    <div class="container-fluid container">
      <div
          v-if="loading"
          class="w-100 d-flex justify-content-center align-items-center"
          style="height: 50vh">
            <div class="loader" style="height: 75px; width: 75px"></div>
          </div>
          <div v-else>
      <div class="d-flex flex-row justify-content-between align-items-center banner pl-4 pr-6" v-if="total < 25  && userEmail !== 'dimitra@bothofus.se'">
        <div class="d-flex flex-row align-items-center">
          <i
            style="font-size: 30px; color: white;"
            class="fas fa-exclamation-triangle mr-4"
          ></i>
          <div class="banner-text">
            You must have a minimum of <span class="green">€25.00</span> to place your order.
            <br>
            Current order total is  <span class="red">€{{total || 0}}</span>
          </div>
        </div>
        <router-link to="/products">
          <div class="btn btn-shop">CONTINUE SHOPPING</div>
        </router-link>
      </div>
      <div class="row">
        <div class="col-md-6 mb-5 mb-xl-0 mt--10">
          <div class="px-6 mt-3">
            <span :class="{'font-weight-bold': currentStage =='shipping'}">Shipping</span> >
            <span :class="{'font-weight-bold': currentStage =='payment'}">Payment </span> >
            <span :class="{'font-weight-bold': currentStage =='summary'}">Summary</span>
          </div>
          <CheckoutShipping v-if="currentStage == 'shipping'" @go-to-payment="goToPayment()" v-on:shipping_price="shippingPrice">
          </CheckoutShipping>

          <CheckoutPayment v-if="currentStage == 'payment'" @go-to-shipping="goToShipping()" @go-to-summary="goToSummary()" >
          </CheckoutPayment>
        </div>
        <div class="col-md-6 mb-5 mb-xl-0 mt--10">
          <div class="pl-lg-4">
            <div class="order px-2">
              <div class="order-title">Order</div>
              <div class="table-responsive">
                <table class="table">
                  <tr v-for="(item, index) in products" :key="index">
                    <td>
                      <img src="../../public/img/icons/info-close.svg" @click="removeFromCart(index)" class="mt-2" style="cursor:pointer; height: auto; width: 25px; padding: 0"/>
                    </td>
                    <td><img :src="appURL + item.image" @error="imageError" height="100" width="100"></td>
                    <td>
                      <p class="product-name text-wrap">{{item.name}}</p>
                      <p class="product-price">
                        €{{item.price}}
                      </p>
                      <p>
                        <input disabled class="qty-input form-control-sm w-50" type="number" v-model="products[index].quantity" @change="updateQuantity(index, products[index].quantity)">
                      </p>
                    </td>
                    <td>
                      <p class="product-subtotal">€{{(item.price * item.quantity).toFixed(2)}}</p>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="d-flex justify-content-end">
                <div class="labels " style="width:120px;">
                  <h3>Subtotal</h3>
                  <h3>Tax</h3>
                  <h3>Shipping</h3>
                </div>
                <div class="summary-data w-auto d-flex flex-column align-items-end pr-3">
                  <h3>€ {{(+this.total).toFixed(2)}}</h3>
                  <h3>€ {{(this.total*0.25).toFixed(2)}}</h3>
                  <h3>€ {{(+this.shipping_price).toFixed(2)}}</h3>
                </div>
              </div>
              <hr>
              <div class="d-flex justify-content-end pb-5 mb-5">
                <div class="labels"  style="width:120px;">
                  <h2>Total</h2>
                </div>
                <div class="summary-data w-25 d-flex flex-column align-items-end pr-3">
                  <h2>€ {{parseFloat(parseFloat(this.total) + parseFloat(this.total*0.25) + parseFloat(this.shipping_price)).toFixed(2) || 0}}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-6" style="margin-bottom: 50px;">
          <div style="  width: 480px;
            height: 1px;
            margin: 0px 0px 20px 14px;
            background-color: #b9c3bc;"></div>
          <a href="/contact-us" style="
            margin-left: 14px;
            font-family: Heebo;
            font-size: 14px;
            font-weight: normal;
            color: #143f49;">Contact Us</a>
          <a href="/privacy" style="
            margin-left: 40px ;
            font-family: Heebo;
            font-size: 14px;
            font-weight: normal;
            color: #143f49;">Privacy Policy</a>
          <a href="/cookies" style="
            margin-left: 40px;
            font-family: Heebo;
            font-size: 14px;
            font-weight: normal;
            color: #143f49;">Cookies</a>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import axios from "axios";
import HeaderMenu from "../views/HeaderMenu";
import CheckoutShipping from "../components/CheckoutShipping";
import CheckoutPayment from "../components/CheckoutPayment";
// import router from "../router";

export default {
  name: "Product",
  components: {
    HeaderMenu,
    CheckoutShipping,
    CheckoutPayment
  },
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      profile: [],
      orders: [],
      cart_items: [],
      total: 0,
      tax: 25,
      shipping_charges: 50,
      loading: false,
      currentStage: "shipping",
      order: {},
      shippingMethod: {},
      products: [],
      shipping_price: 0,
      userEmail: localStorage.getItem('email') || ''
    };
  },
  methods: {
    getShippingPrices: function () {
      let url = "user/shipping-price";
      axios({
        method: "POST",
        url: url,
        data: {
          products: JSON.parse(localStorage.getItem('cart'))
        },
        headers: {
          "content-type": "application/json",
        },
      }).then((result) => {
            if (result) {
              this.shipping_price = result.data.shipping_price;
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    getCart(close = true){
      if(close) {
        this.visible = !this.visible;
      }
      if(this.visible){
          this.products = JSON.parse(localStorage.getItem('cart'));
          this.total = 0.0;
          this.products.forEach(product => {
            this.total += product.price * product.quantity;
          });
      }
    },
    imageError: function(e){
      e.target.src = "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"
    },
    updateQuantity(index, quantity){
      if(quantity >= 1){
        this.products[index].quantity = quantity;
      } else {
        this.products.splice(index, 1);
      }
      this.commitCart();
    },
    removeFromCart(index){
       this.$logEvent(this.$analytics,"remove_from_cart");
      this.products.splice(index, 1);
      this.commitCart();
    },
    commitCart(){
      localStorage.setItem('cart', JSON.stringify(this.products));
      this.total = 0.0;
      this.products.forEach(product => {
        this.total += product.price * product.quantity;
      });
    },
    goToPayment:function(){
      this.currentStage = "payment";
        window.scrollTo(0,0);
    },
    goToSummary:function(){
      this.currentStage = "summary";
    },
    goToShipping:function(){
      this.currentStage="shipping";
    },
    shippingPrice: function(id) {
			this.shipping_price = id;
      localStorage.setItem("shipping", id);
    },
  },
  beforeCreate() {
    localStorage.setItem('search', '')
  },
  mounted() {
    this.getShippingPrices();
    this.getCart();
    let page = this.$route.query.page;
    if(page){
      if(page =='payment'){
        this.goToPayment();
      }
    }
  },
};
</script>
<style lang="scss">
.container {
  max-width: 1300px;
}

.title{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #424242;
  letter-spacing: normal;
}
.header{
  padding: 8px 260px 6px 30px;
  border-radius: 4px;
  background-color: #deeddf;
}

.info{
      font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.order{
  background-color: #deeddf;
  min-height: 700px;
}

.quantity{
    max-width: 50px !important;
    max-height: 40px !important;
}

.banner{
  border-radius: 4px;
  background-color: #457b80;
  min-height: 100px;
  padding: 20px;
}



.banner-text{
  font-family: Heebo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
}

.btn-shop {
  font-family: Heebo;
  font-size: 14px;
  width: 205px;
  background-color: #e7615a;
  color: white;
}

.btn-shop:hover {
  color: rgba(255,255,255,0.9);
}

.green{
  color: #c1d365;
}

.red{
  color: #e88884;
}

.order-title{
  font-family: "Heebo";
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 50px;
  padding-top: 31px;
}

.order{
  *,h2,h3{
    color: #424242 !important;
  }

  .product-image{
    border-radius: 4px;
  }

  .name{
    font-size: 16px;
  }

  .price,.subtotal{
    font-size: 16px;
    font-weight: normal;
  }

  .form-control.quantity{
    height:32px !important;
    font-size: 14px !important;
    border-radius: 4px !important;
    box-shadow: inset 0 1px 5px 0 rgba(185, 195, 188, 0.4) !important;
    border: solid 1px #b9c3bc !important;
    margin-top:10px;
  }
}

.summary-data{
  h3{
    font-weight:normal;
  }
}
</style>
