<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="56px" height="72px" viewBox="0 0 56 72" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>B134BBE9-14EB-484F-8448-7F3126397A76</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-621.000000, -1354.000000)">
            <g id="book" transform="translate(596.000000, 1340.000000)">
                <rect id="Rectangle-Copy-32" transform="translate(49.500000, 49.000000) scale(-1, 1) translate(-49.500000, -49.000000) " x="0" y="0" width="99" height="98"/>
                <path d="M31.96875,16.84375 L73.21875,16.84375 C75.4969246,16.84375 77.34375,18.6555977 77.34375,20.890625 L77.34375,69.453125 C77.34375,71.6881523 75.4969246,73.5 73.21875,73.5 L31.96875,73.5 C29.6905754,73.5 27.84375,71.6881523 27.84375,69.453125 L27.84375,20.890625 C27.84375,18.6555977 29.6905754,16.84375 31.96875,16.84375 Z" id="Rectangle" stroke="#FFFFFF" stroke-width="5.5" stroke-linejoin="round" transform="translate(52.593750, 45.171875) scale(-1, 1) translate(-52.593750, -45.171875) "/>
                <path d="M72.703125,36.75 L72.703125,78.6210623 C72.703125,80.8668938 70.8487704,82.6875 68.5613081,82.6875 L35.990789,82.6875 C30.1725567,82.6875 27.4706555,79.9765415 27.8850851,74.5546246" id="Rectangle" stroke="#FFFFFF" stroke-width="5.5" stroke-linejoin="round" transform="translate(50.273438, 59.718750) scale(-1, 1) translate(-50.273438, -59.718750) "/>
                <path d="M51.8203125,53.59375 C46.2672619,53.59375 41.765625,49.1375842 41.765625,43.640625 C41.765625,38.1436658 46.2672619,33.6875 51.8203125,33.6875 C57.3733631,33.6875 61.875,38.1436658 61.875,43.640625 C61.875,49.1375842 57.3733631,53.59375 51.8203125,53.59375 Z" id="oval" stroke="#FFFFFF" stroke-width="5.5" transform="translate(51.820312, 43.640625) scale(-1, 1) translate(-51.820312, -43.640625) "/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'books'
    }
</script>

<style lang="scss" scoped>

</style>