<template>
  <div class="bg-white min-vh-100" style="min-width: 100%">
    <HeaderMenu />
    <IndividualOrBusiness/>
    <div class="entry-content">
      <div class="container-fluid">
        <div
            v-if="loading"
            class="w-100 d-flex justify-content-center align-items-center"
            style="height: 50vh"
        >
          <div class="loader" style="height: 75px; width: 75px"></div>
        </div>
        <div v-else class="container pb-3">
          <div class="top-row d-flex justify-content-between">
            <div class="product-title m-0"><a href="/" style="color:black">Home</a> <span class="mx-2"> <i class="fa fa-angle-right"></i> </span><a :href="'/products?cat=' + product.category.slug" style="color:black"> {{product.category.name}}</a> <span class="mx-2"> <i class="fa fa-angle-right"></i> </span> <b> {{product.name}} </b></div>
          </div>
          <div class="row m-0">
            <div class="col-md-6 row justify-content-center">
              <img
                  v-if="product.image"
                  :src="largeImageUrl"
                  @error="imageError"
                  alt="product image"
                  class="large_image"
                  style="width: 100%; height: 450px; object-fit: contain"
              />
              <img  v-else src="http://lifechangely.com/be-staging/public/uploads/courses/25d54ab0c06b410e91f7149fe1d43e86.png"
                    class="my-3" alt="default image" style="width: auto; max-height: 500px;">
              <div class="row align-items-baseline h-25">
                <div class="grid-container">
                  <div class="grid-item">
                    <img
                        @click="switchImage(appURL + product.image)"
                        v-if="product.image && product.image"
                        :src="appURL + product.image"
                        @error="missingImage1"
                        alt="product image 1"
                        style="width: 100%; height: 100%; margin-right:1.9%; object-fit: scale-down"
                    />
                  </div>
                  <div class="grid-item">
                    <img
                    @click="switchImage(appURL + product.image_1)"
                    v-if="product.image_1 && image1"
                    :src="appURL + product.image_1"
                    @error="missingImage1"
                    alt="product image 1"
                    style="width: 100%; height: 100%; margin-right:1.9%; object-fit: scale-down"
                    />
                  </div>
                  <div class="grid-item">
                    <img
                    @click="switchImage(appURL + product.image_2)"
                    v-if="product.image_2 && image2"
                    :src="appURL + product.image_2"
                    @error="missingImage2"
                    alt="product image 2"
                    style="width: 100%; height: 100%; margin-right:1.9%; object-fit: scale-down"
                />
                  </div>
                  <div class="grid-item">
                    <img
                    @click="switchImage(appURL + product.image_3)"
                    v-if="product.image_3 && image3"
                    :src="appURL + product.image_3"
                    @error="missingImage3"
                    alt="product image 3"
                    style="width: 100%; height: 100%; margin-right:1.9%; object-fit: scale-down"
                />
                  </div>
                </div>




              </div>
            </div>
            <div class="col-md-6 justify-content-center mt-5 pl-5 product-details">
              <h2>{{product.name}}</h2>
              <div class="mt-2">€ {{product.price}}</div>
              <div class="mt-2 row" style="margin-left: -5px;" @click="token === null && goToLogin()">
                <star-rating
                    :star-points="[
                                  23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                                  34, 46, 19, 31, 17,
                                ]"
                    :increment="0.5"
                    :read-only="true"
                    :round-start-rating="false"
                    :border-width="3"
                    :rating="parseFloat(product.rating)"
                    :show-rating="false"
                    :star-size="18"
                    inactive-color="#ffffff"
                    active-color="#424242"
                    v-model="star"
                >
                </star-rating>
                <div class="ml-3">
                  {{ratingCount}}
                </div>
              </div>
              <div class="mt-2">{{product.description}}</div>
              <div class="mt-3" v-if="product.material"><span class="title">Materials:</span> {{product.material.name}}</div>
              <div v-if="product.size" class="mt-3"><span class="title">Size:</span> {{product.size}}</div>
              <div class="row"><span class="mt-3 ml-3 mr-2 title d-flex align-items-center">Quantity:</span>
                <select
                    v-model="quantity"
                    class="resource-select custom-select mt-3"
                    style="width: 160px !important"
                >
                  <option value="1">1 unit</option>
                  <option value="10" selected>10 units</option>
                  <option value="100">100 units</option>
                  <option value="250">250 units</option>
                  <option value="500">500 units</option>
                </select>
              </div>

              <div class="btn btn-add-to-cart" @click="addToCart">ADD TO CART</div>
              <!-- <img src="../../public/img/icons/add-to-cart.svg" alt="" class="mt-5" style="cursor:pointer;" @click="addToCart"> -->
              <div class="row mt-5 offset-3" v-if="currentProduct"><div class="loader"></div></div>
              <div class="row mt-5 offset-3" v-if="product.wishlist" style="margin-left: 35%" @click="addToWishlist(product.id, product)">
                <i class="fal fa-heart mt-1" width="19" height="19" ></i>
                <span class="ml-2" style="text-decoration: underline;"> Added to Wishlist </span>
              </div>
              <div class="row mt-5 offset-3" v-else-if="changeWishlistNoLogin" style="margin-left: 35%" @click="addToWishlist(product.id, product)">
                <i class="fas fa-heart mt-1" width="19" height="19" ></i>
                <span class="ml-2" style="text-decoration: underline;"> Added to Wishlist </span>
              </div>
              <div class="row mt-5 offset-3" v-else style="cursor:pointer; margin-left: 35%" @click="addToWishlist(product.id, product)">
                <i class="fal fa-heart mt-1" width="19" height="19"></i>
                <span class="ml-2" style="text-decoration: underline;"> Add to Wishlist </span>
              </div>
              <hr>
              <div class="row mt-1 mb-1">
                <div class="title pr-3">Shipping Details: </div>
                   <div class="col-8">
                  <div class="summary-heading">
                    Priority Shipping, DHL Priority
                  </div>
                  <div class="summary-details mb-2">
                    16 to 20 days. Home delivery.
                  </div>
                   <div class="summary-heading">
                    Urgent Shipping, EMS
                  </div>
                  <div class="summary-details">
                    7 to 14 days. Home delivery.
                  </div>
                </div>
                <div class="summary-heading-normal">

                </div>
              </div>
              <hr>
              <div class="mt-1 mb-1 row title">
                <div class="row align-items-center justify-content-start" style="cursor: pointer;" v-if="!rating">
                  <div class="col-md-2 col-sm-12 col-xs-12">
                    <div class="">
                      Score:
                    </div>
                  </div>
                  <div class="col-md-6" @click="token === null && goToLogin()">
                    <star-rating
                        :read-only="token === null"
                        :star-points="[
                                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                                      34, 46, 19, 31, 17,
                                    ]"
                        :increment="0.5"
                        :border-width="3"
                        :rating="parseFloat(product.rating)"
                        :show-rating="false"
                        :star-size="18"
                        inactive-color="#ffffff"
                        active-color="#424242"
                        border-color="#424242"
                        v-model="star"
                    >
                    </star-rating>
                  </div>
                  <div class="col-md-1 ">
                    <button class="btn-rate" @click="rateProduct" style="background-color: white; width: 125px;
                                  border: 1px solid black;
                                  border-radius: 4px;
                                  height: 40px;
                                  font-size: 14px;
                                  font-weight: bold;
                                  text-align: center;">RATE PRODUCT</button>
                  </div>
                </div>
                <div class="row ml-4" style="cursor: pointer;" v-else>
                  <!-- <star-rating
                      :star-points="[
                                      23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36,
                                      34, 46, 19, 31, 17,
                                    ]"
                      :increment="0.5"
                      :border-width="3"
                      :rating="parseFloat(rating.rating)"
                      :show-rating="false"
                      :read-only="true"
                      :star-size="20"
                      inactive-color="#ffffff"
                      active-color="#424242"
                      border-color="#424242"
                  >
                  </star-rating> -->
                  <star-rating
                      :rateRange="3"
                      :maxIcon="5"
                      :iconHeight="22"
                      :iconWidth="22"
                      :hasCounter="true"
                      iconColor="#fffff"
                      iconColorHover="#424242"
                      iconShape="star"
                  ></star-rating>
                  <button style="background-color: white; width: 125px;
                                  border: 1px solid black;
                                  border-radius: 10px;
                                  margin-left: 60px;
                                  height: 40px;
                                  font-size: 14px;
                                  font-weight: bold;
                                  text-align: center;">RATED</button>
                </div>
              </div>
            </div>
          </div>
          <!-- products -->
          <h3 class="m-0 mt-5">Similar Products</h3>
          <template v-if="relatedProducts.length">
            <VueSlickCarousel v-bind="settings">
              <div
                  class="col-md-12 products bg-white mx-0 my-4 p-2"
                  v-for="(product) in relatedProducts.filter(product => product.id !== this.$route.params.product_id)"
                  :key="product.id"
              >
                <div class="add-wishlist"
                     @click="addToWishlist(product.id,product)">
                  <div v-if="product.updatingWishlist">
                    <div class="loader"></div>
                  </div>
                  <div v-else>
                    <i v-if="product.wishlist" class="fas fa-heart "></i>
                    <i v-else class="fal fa-heart"></i>
                  </div>
                </div>
                <div class="img-div" style="color: #424242 !important; cursor: pointer !important;" @click="goToProduct(product.id)" >
                  <div class="bg-black text-center mt-3"
                  >
                    <img
                        class="popular-product-image w-100"
                        v-if="product.image"
                        :src="appURL + product.image"
                        @error="imageError"
                        alt="product image"
                    />
                    <img v-else
                         src=""
                         class="my-3"
                         alt="default image"
                         style="
                          width: 150px;
                        height: 150px;
                          padding-left:50px !important;">
                  </div>
                  <p  @click="goToProduct(product.id)" class="text-center resource-large-text m-0 p-0 mt-3 text-wrap" style="  font-size: 16px !important; font-weight: bold !important;">
                    {{ product.name }}
                  </p>
                  <p class="text-center resource-large-text m-0 p-0 mt-2">
                    € {{ product.price }}
                  </p>
                  <div class="mt-2 text-center">
                    <a href="#" @click.prevent="goToProduct(product.id)">
                      <div class="btn btn-buy w-100">BUY</div>
                    </a>
                  </div>
                  <div class="row justify-content-center mb-3"></div>
                </div>
              </div>
            </VueSlickCarousel>
          </template>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import StarRating from "vue-star-rating";
import axios from "axios";
import HeaderMenu from "../views/HeaderMenu";
import Footer from "../components/Footer";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import IndividualOrBusiness from "@/views/Notifications/IndividualOrBusiness";

export default {
  name: "Product",
  components: {
    IndividualOrBusiness,
    HeaderMenu,
    VueSlickCarousel,
    Footer,
    StarRating,
  },
  data: () => {
    return {
      loading: true,
      largeImageUrl: '-',
      appURL: process.env.VUE_APP_MAIN_URL,
      products: [],
      rating: [],
      total: 1,
      search: "",
      sort: "name",
      url: "",
      changeWishlistNoLogin: false,
      product: {updatingWishlist: false},
      currentProduct: false,
      relatedProducts: {},
      quantity: 10,
      star: 0,
      ratingCount: 0,
      image1: true,
      image2: true,
      image3: true,
      settings: {
        "dots": false,
        "infinite": true,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "arrows" : false
            }
          }
        ]
      },
      token: localStorage.getItem('access_token')
    };

  },
  methods: {
    goToLogin(){
      this.$router.push('/login');
    },
    updateCartQuantity(){
      // eslint-disable-next-line no-undef
      this.$emit('update-cart-quantity')
    },
    goToProduct: function(id){
      this.$router.push('/product/'+ id);
      this.getProduct();
    },
    switchImage: function(url){
      this.largeImageUrl = url
    },
    searchKeydown: function (e) {
      if (e.keyCode === 13) {
        if (this.search.length >= 3 || this.search == "") {
          this.getProduct();
        } else {
          this.$noty.error("Please enter 3 or more characters",{ closeWith : ['button']});
        }
      }
    },
    searchInput: function () {
      if (!this.search) {
        this.getProduct();
      }
    },
    missingImage1: function(){
      this.image1 = false;
    },
    missingImage2: function(){
      this.image2 = false;
    },
    missingImage3: function(){
      this.image3 = false;
    },
    getProduct: function () {
      let url = `user/products/${this.$route.params.product_id}?access_token=${localStorage.getItem('access_token')}`;
      this.loading = true
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then((result) => {
            if (result) {
              this.product = result.data.product;
              this.rating = result.data.rating;
              this.product.updatingWishlist = false;
              this.relatedProducts = result.data.related_product;
              this.largeImageUrl = this.appURL + result.data.product.image
              this.star = result.data.rating !== null ? parseFloat(result.data.rating.rating) : 0
              this.ratingCount = result.data.ratings_count
              this.relatedProducts.forEach((product)=>{
                product.updatingWishlist = false;
              })

              // Check if in offline wishlist
              let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items")) ;

              if(wishlist_items == null){
                wishlist_items = [];
              }
              if(wishlist_items != null){
                this.relatedProducts.forEach(product => {
                  product.updatingWishlist = false;
                  wishlist_items.includes(product.id)?
                      product.wishlist = true:false
                  wishlist_items.includes(this.product.id)?
                      this.changeWishlistNoLogin = true:false
                });
              }
            }
          }
      ).catch((error) => {
        console.log(error.response);
      }).finally(() => {
        this.loading = false;
      });
    },
    rateProduct: function(){
      const token = localStorage.getItem("access_token");
      if(!token){
        this.goToLogin()
      }
      else{
        const data = {
          product_id: this.$route.params.product_id,
          rating: this.star,
        }

        let url = `user/ratings/add?access_token=${localStorage.getItem('access_token')}`;
        axios({
          method: "POST",
          url: url,
          data: data,
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.star = 0
                this.$noty.success('Rating Added Successfully.',{ closeWith : ['button']});
                this.getProduct();
              }
            },
            (error) => {
              console.log(error);
            }
        );
      }
    },
    removeItem: function(arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },
    imageError: function(e){
      e.target.src = "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"
    },

    addWishListLocally: function (product){
      let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items"));

      if(wishlist_items == null){
        wishlist_items = [];
      }

      if(!wishlist_items.includes(product.id)){
        this.changeWishlistNoLogin = true;
        wishlist_items.push(product.id);
        localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
        this.$noty.success('Item successfully added to wishlist',{ closeWith : ['button']})
        product.updatingWishlist = false;
        setTimeout(()=>{
          product.wishlist = true;
          product.updatingWishlist = false;
          // this.getProduct();
        }, 500);

      }
      else{
        this.changeWishlistNoLogin = false;
        wishlist_items = this.removeItem(wishlist_items, product.id);
        localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));
        this.$noty.success('Item successfully removed from wishlist',{ closeWith : ['button']})
        setTimeout(()=>{
          product.wishlist = false;
          product.updatingWishlist = false;
          // this.getProduct();
        }, 500);
      }
    },
    addToWishlist: function(id, product){
      product.updatingWishlist = true;
      this.products = Object.assign({},this.products);

      const token = localStorage.getItem("access_token");

      this.addWishListLocally(product)
      if(token){
        let url = '';
        let method = '';
        if(!product.wishlist){

          url = `user/wishlist/add?access_token=${localStorage.getItem('access_token')}&product_id=${id}`;
          method = 'POST';
        }
        else{
          url = `user/wishlist/delete?access_token=${localStorage.getItem("access_token")}&product_id=${id}`;
          method = 'DELETE';
        }
        axios({
          method: method,
          url: url,
          headers: {
            "content-type": "application/json",
          },
        }).then((result) => {
              // eslint-disable-next-line no-undef
              Fire.$emit('added_to_wishlist');
              this.$logEvent(this.$analytics,"add_to_wishlist");
              this.$logEvent(this.$analytics,"user_engagement");
              result;
            },
            (error) => {

              if (error.response.status == 401) {
                this.$router.push({path: "/login"})
              }
              console.log(error);
            }
        );

        // this.getProduct();
        // setTimeout(()=>{
        //   product.updatingWishlist = false;
        // }, 100);

        // eslint-disable-next-line no-undef
        Fire.$emit('added_to_wishlist');
      }
    },

    addOrRemoveItem: function (cartItems, newItem){
      const i = cartItems.findIndex(itm => itm.id === newItem.id)
      if (i > -1) {
        cartItems[i] = {...newItem, quantity: parseInt(this.quantity) + parseInt(cartItems[i].quantity)}
      } else {
        cartItems.push(newItem)
      }
      return cartItems
    },
    addToCart: function(){
      let cart = JSON.parse(localStorage.getItem('cart'));
      this.product.quantity = this.quantity;
      this.$logEvent(this.$analytics,"add_to_cart");
      const newCart =  this.addOrRemoveItem(cart, this.product)
      localStorage.setItem('cart', JSON.stringify(newCart));
      // eslint-disable-next-line no-undef
      Fire.$emit('update-cart-quantity');
      this.quantity = 10;
      this.$noty.success('Product added to cart',{
        timeout: 1000,
        closeWith : ['button']
      });
      setTimeout(() => {
        this.updateCartQuantity()
      }, 100)
    },
  },
  mounted() {
    this.getProduct();
    this.url = axios.defaults.baseURL;
    this.$logEvent(this.$analytics,"view_item");
    this.$logEvent(this.$analytics,"user_engagement");
  },
};
</script>

<style lang="scss" >

.slick-prev, .slick-next {
  font-size: 0 !important;
  line-height: 0 !important;
  position: absolute !important;
  top: 50% !important;
  display: block !important;
  width: 65px !important;
  height: 113px !important;
  padding: 0 !important;
  -webkit-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  cursor: pointer !important;
  color: transparent !important;
  outline: transparent !important;
  background: transparent !important;
}
.slick-next {
  right: -60px !important;
}
.slick-prev {
  left: -60px !important;
}
.slick-next:before {
  content: "\f105" !important;
  font-family: FontAwesome !important;
  color: #e7615a !important;
  font-size: 30px !important;
}
.slick-prev:before {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  color: #e7615a !important;
  font-size: 30px !important;
}
.img-div:hover {
  cursor: pointer;
}
.quantity{
  max-width: 100px !important;
  max-height: 40px !important;
}

.title{
  font-size: 18px !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
  color: #424242 !important;
}
.cursor-pointer{
  cursor: pointer;
}

.btn-add-to-cart:hover{
  color: white;
}
.add-wishlist{
  right: 22%;
}

.btn-add-to-cart{
  margin-top: 38px;
  font-size: 14px;
  font-weight: bold;
  background-color: #e7615a;
  color: white;
  width: 100%;
}

.fa-heart{
  color: black;
}
.popular-product-image {
  width: 250px;
  height: 250px;
  object-fit: contain !important;
}

.summary-title {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.summary-heading {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
@media only screen and (max-width: 600px) {
  .large_image {
    height: 300px !important;
  }
  .popular-product-image {
    width: 150px !important;
    height: 150px !important;
    object-fit: contain !important;
  }
  .btn-rate {
    margin-top: 3px;
  }
}

.btn-buy{
  background: #e7615a !important;
  color: white;
  border: none;
  height: 45px;
  width: 150px;
  border-radius: 2px;
  font-weight: bold;
}

.grid-container {
  display: inline-grid;
  grid-template-columns: auto auto auto auto;
  padding: 10px;
  column-gap: 20px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: solid #fff 1px;
  text-align: center;
}
</style>
