<template>
    <div class="image">
       <HeaderMenu/>
        <div class="main-content">
          <div class="container">
            <div class="row justify-content-center">
              <div class="my-5">
                <div style=" padding: 0px;  width: 700px;" class="card bg-white shadow border-0">
                  <div class="card-body px-lg-5 py-lg-5" >
                    <form role=" form-floating">
                      <h1 style="font-family: ZillaSlab;
  font-size: 32px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  margin-left: 71px;
  letter-spacing: normal;
  font-family: 'Heebo', sans-serif;">Get In Touch</h1>
                        <p class="mb-4" style="font-family: Heebo; font-size: 16px;
                          font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  margin-left: 71px;
  letter-spacing: normal;">Fill out this form or send an <a style="text-decoration: underline;text-decoration-color: darkgrey; color:black; text-underline-offset: 1.5px;" href="mailto:contact@plasticfreeeurope.org">email</a></p>
                      <div class="email-input-group form-label-group mx-auto d-none">
                        <input
                            type="text"
                            id="inputEmail"
                            class="form-control border-silver"
                            placeholder="Website"
                            v-model="model.website"
                            autofocus=""
                        />
                        <label for="inputUsername">Website</label>
                      </div>
                      <div class="email-input-group form-label-group mx-auto ">
                        <input
                          type="text"
                          id="inputUsername"
                          class="form-control border-silver"
                          placeholder="Name or Username"
                          required=""
                          v-model="model.name"
                          autofocus=""
                        />
                      </div>

                      <div
                        class="password-input-group input-group form-label-group mx-auto border-0 border-right-0"
                      >
                        <input
                          type="email"
                          id="password"
                          class="form-control border-silver "
                          placeholder="Email"
                          required=""
                          v-model="model.email"
                          autofocus=""
                        />
                      </div>
                      <div
                        class="password-input-group input-group form-label-group mx-auto border-0 border-right-0"
                      >
                        <textarea
                          type="email"
                          id="message"
                          class="form-control border-silver "
                          placeholder="Message"
                          required=""
                          rows="8"
                          v-model="model.message"
                          autofocus=""
                        >
                        </textarea>
                      </div>
                      <div v-if="errorMessage" class="mx-auto error-message">
                        {{errorMessage}}
                      </div>

                      <div style="margin-left: 71px;" class="form-group">
                        <div class="form-check">
                          <input v-validate="'required'" class="form-check-input" name="privacy-policy" type="checkbox" id="policyLabel" v-model="acceptPolicy">
                          <label class="form-check-label" for="policyLabel">I agree to the <router-link style="color: #143f49;text-decoration: underline" to="/privacy">GDPR and data policy</router-link></label>
                        </div>
                        <p class="text-danger mb-0" v-if="errors.first('privacy-policy')"><small>You need to accept the GDPR and data policy</small></p>
                      </div>

                      <div class="text-center mt-2">
                        <div class="btn btn-link login-btn mx-auto" @click="login">
                          <div class="loader mx-auto" v-if="loading"></div>
                          <div v-else>SEND</div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
// import BasePFEButton from "../components/BasePFEButton.vue";
import HeaderMenu from "../views/HeaderMenu";
import axios from 'axios';

export default {
   components: { HeaderMenu },
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
        website: "",
      },
      showPassword:false,
      loading:false,
      errorMessage: "",
      acceptPolicy: false,
    };
  },
  methods: {
    login: function () {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.loading = false;
          return;
        }
        let url = 'contact';
        axios({
          method: "POST",
          url: url,
          data: {
            email: this.model.email,
            name: this.model.name,
            message: this.model.message,
            website: this.model.website
          },
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.loading = false;
                alert('Contacted successfully')
                this.$router.push('/');
              }
            },
            (error) => {
              this.loading = false;
              this.errorMessage = error.response.data.message;
              console.log(error);
            }
        );
      });
    },
  },
};
</script>
<style>
.image{
  width: 100%;
  height: 100vh;
  background-image: url('../layout/contact-bg.jpg') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

body {
  font-family: "Heebo";
  color: #424242;
}

.card {
  min-width: 560px;
  border-radius: 16px;
}

.forgot {
  font-family: "Heebo";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.no-account {
  /* width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px; */
  margin-top: 52px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
.sign-up {
  color: #e7615a;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  width: 460px !important;
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input::-webkit-input-placeholder, .form-label-group textarea::-webkit-input-placeholder{
  color: #b9c3bc !important;
}

.form-control::placeholder {
  color: red;
}

#inputUsername,#password, #message{
  font-size: 14px !important;
}

.input-group-text.border-left-0.rounded-0.rounded-right{
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: solid 1px #b9c3bc;
  background: transparent !important;
}

.password-input-group,.email-input-group{
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
}

.form-control{
  background: transparent !important;
}

h1{
  color: #424242;
}

.login-btn{
  height: 40px;
  width: 292px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  background-color: #e7615a;

  font-size:14px;
  font-family: "Heebo";
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
}

.login-btn:hover{
  color:rgba(255,255,255, 0.85);
}

.text-reset{
  text-decoration: underline;
}

.error-message{
  color: #e7615a;
  width: 292px;
}

input:-webkit-autofill,input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
</style>
