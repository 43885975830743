<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card bg-white shadow border-0">
          <div class="card-body">
            <form role=" form-floating">
              <p class="main-title">Email Confirmed</p>
              <div class="no-account">
                Thank you for confirming your email, move to
                <span class="sign-up"
                  ><router-link to="/signup" class="text-reset"
                    >Login!</router-link
                  ></span
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BasePFEButton from "../components/BasePFEButton.vue";
import axios from "axios";

export default {
  // components: { BasePFEButton },
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      showPassword: false,
      loading: false,
      errorMessage: "",
    };
  },
  methods: {
    confirmEmail: function () {
      this.loading = true;
      let url = "user/confirm-email";
      axios({
        method: "POST",
        url: url,
        data: {
          email: this.model.email,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.loading = false;
            localStorage.setItem("access_token", result.data.auth.access_token);
            localStorage.setItem(
              "access_token_expires_at",
              result.data.auth.expires_at
            );
            localStorage.setItem("name", result.data.user.name);
            localStorage.setItem("email", result.data.user.email);
            localStorage.setItem("user_id", result.data.user.id);
            localStorage.removeItem("wishlist_items");
            localStorage.removeItem("cartItems");
            location.assign("/profile");
          }
        },
        (error) => {
          this.loading = false;
          this.errorMessage = error.response.data.message;
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.model.email = this.$route.query.email;
    this.confirmEmail();
  },
};
</script>
<style>
body {
  font-family: "Heebo";
  color: #424242;
}

.card {
  min-width: 560px;
  border-radius: 16px;
}

.forgot {
  font-family: "Heebo";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.no-account {
  /* width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px; */
  margin-top: 52px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
.sign-up {
  color: #e7615a;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 2.125rem;
  padding: 0.75rem;
}

.form-label-group label {
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #b9c3bc;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.75rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:-webkit-autofill ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .form-label-group label {
    position: static;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #b9c3bc;
  }
}

.form-control::placeholder {
  color: red;
}

#inputEmail,
#password {
  font-size: 14px !important;
}

.input-group-text.border-left-0.rounded-0.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: solid 1px #b9c3bc;
  background: transparent !important;
}

.password-input-group,
.email-input-group {
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
}

.form-control {
  background: transparent !important;
}

h1 {
  color: #424242;
}

.login-btn {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  background-color: #e7615a;
  font-size: 14px;
  font-family: "Heebo";
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
}

.login-btn:hover {
  color: rgba(255, 255, 255, 0.85);
}

.text-reset {
  text-decoration: underline;
}

.error-message {
  color: #e7615a;
  width: 292px;
}

.card-body {
  padding: 15% !important;
}

.main-title {
  font-family: "Heebo", sans-serif;
  font-size: 24px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #424242;
  margin-bottom: 20px !important;
}
</style>
