<template>
  <div v-show="!accepted">
      <div
        class="box d-flex flex-row  align-items-center  justify-content-center "
      >
        <div class="div d-flex  col-md-10 align-items-center ">
          <div class="pl-3 mr-5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="29"
            viewBox="0 0 32 29"
            class="text-end"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <g>
                  <path
                    d="M0 0H41V41H0z"
                    transform="translate(-196 -604) translate(192 598) matrix(-1 0 0 1 41 0)"
                  />
                  <path
                    fill="#FFF"
                    fill-rule="nonzero"
                    d="M20.083 35c5.838-3.571 10.104-7.276 12.797-11.116 2.319-3.302 3.345-6.47 3.079-9.504-.107-1.343-.467-2.578-1.08-3.706-.56-1.047-1.32-1.96-2.28-2.738-.906-.725-1.919-1.249-3.039-1.571-1.12-.322-2.266-.416-3.439-.282-1.653.215-3.012.752-4.079 1.611-.88.698-1.56 1.584-2.04 2.658-.479-1.074-1.145-1.96-1.999-2.658-1.12-.86-2.506-1.396-4.159-1.611-.293-.054-.573-.08-.84-.08-1.119-.027-2.212.167-3.278.583-1.067.417-2.013 1.027-2.84 1.833-.88.805-1.546 1.732-2 2.779-.532 1.154-.826 2.39-.879 3.705-.107 2.98 1 6.082 3.32 9.303 2.745 3.76 6.944 7.357 12.596 10.794.027-.027.053-.04.08-.04.053 0 .08.013.08.04z"
                    transform="translate(-196 -604) translate(192 598)"
                  />
                </g>
              </g>
            </g>
          </svg>
        </div>

        <div
          class=" col-8 ">

          <p class="cookie-text  mb-0">
            This website uses cookies in order to offer you the most relevant
            information. By continuing using this website you consent to use of
            the cookies. See our <a href="/privacy">Privacy Policy</a>.
          </p>
        </div>
        <div class="col text-right">
          <button type="button" @click="acceptCookies" class="allow-button btn">
            Allow Cookies
          </button>
        </div>
        </div>
      </div>
    </div>

</template>

<script>
export default {
    name: "cookieBanner",
    data(){
      return {
        accepted: 0,
      }
    },
  methods:{
    acceptCookies(){
      localStorage.setItem("cookiesAccepted", '1');
      this.accepted = 1;
    }
  },
  mounted() {
    if(localStorage.getItem("cookiesAccepted") !== null) {
      this.accepted = parseInt(localStorage.getItem("cookiesAccepted"));
    } else {
      localStorage.setItem("cookiesAccepted", '0');
      this.accepted = false;
    }
  }
};
</script>

<style lang="scss" scoped>


.jumbotron {
  padding: 0;
}

.box {
  min-height: 100px;
  width: 100%;
  background-color: #457b80;
  position: relative;
  z-index: 99;
  margin-top: -100px;
}

.btn-outline-primary {
    color: white;
}

.allowbutton {
  justify-self: end;
  color: white;
  width: 90px;
}

.cookie-text {
    font-family: 'Heebo';
    line-height: 21px;
    color: white;
    font-size: 14px;
    max-width: 560px;
    text-align: justify;

  a{
    color: white;
    text-decoration: underline;
  }
}

.allow-button{
    border: 1px solid ;
    color: #e2e8e4;
    outline-color: #e2e8e4;
}

</style>
