<template>
  <div class="bg-white min-vh-100" style="min-width: 100%">
    <HeaderMenu />
    <IndividualOrBusiness/>
    <MaintenanceNotifications/>
    <PopupNotifications/>
    <div>
      <VueSlickCarousel
          :arrows="true"
          :dots="false"
          :autoplay="true"
          :autoplaySpeed="5000"
      >
        <div>
          <div class="caption-wrapper">
            <div class="caption">
              <div class="d-flex flex-column">
                <span class="caption-title">No More</span>
                <span class="caption-title">Microplastics</span>
                <span class="caption-description pb-5"
                >Shop eco-friendly bamboo straws and help reduce ocean
                  pollution.</span
                >
                <router-link
                    :to="{ path: '/products' }"
                    type="button"
                    class="btn btn-shop text-white text-nowrap p-2"
                >
                  SHOP NOW
                </router-link>
              </div>
            </div>
          </div>
          <img class="carousel-image" src="../assets/background-1.png" />
        </div>
        <div>
          <div class="caption-wrapper">
            <div class="caption">
              <div class="d-flex flex-column">
                <span class="caption-title">Plastic Alternatives,</span>
                <span class="caption-title">all in one place</span>
                <span class="caption-description pb-5"
                >Let’s save the world from plastics!</span
                >
                <router-link
                    :to="{ path: '/products' }"
                    type="button"
                    class="btn btn-shop text-white text-nowrap p-2"
                >
                  SHOP NOW
                </router-link>
              </div>
            </div>
          </div>
          <img class="carousel-image" src="../assets/background-2.png" />
        </div>
        <div>
          <div class="caption-wrapper">
            <div class="caption">
              <div class="d-flex flex-column">
                <span class="caption-title">New Palm Leaf </span>
                <span class="caption-title">Products </span>
                <span class="caption-description pb-5"
                >Sustainable and handcrafted tableware for all
                  occasions.</span
                >
                <router-link
                    :to="{ path: '/products' }"
                    type="button"
                    class="btn btn-shop text-white text-nowrap p-2"
                >
                  SHOP NOW
                </router-link>
              </div>
            </div>
          </div>
          <img class="carousel-image" src="../assets/background-3.png" />
        </div>
      </VueSlickCarousel>
      <div class="cookie-banner">
        <CookieBanner />
      </div>
    </div>
    <div class="popular div product-list">
      <div class="container p-sm-3">
        <div class="row">
          <div class="col-md-12 col-sm-12 ml-md-5 pl-md-5">
            <p class="product-title">Popular Products</p>
          </div>
        </div>
        <div class="col-md-12" v-if="products.length">
          <VueSlickCarousel v-bind="settings">
            <div
                style="height: 150px"
                class="col-md-12 d-flex align-content-center products bg-white mx-0 my-4 p-0"
                v-for="product in productsData1"
                :key="product.id"
            >
              <div
                  class="add-wishlist"
                  @click="addToWishlist(product.id, product)"
              >
                <div v-if="product.updatingWishlist">
                  <div class="loader"></div>
                </div>
                <div v-else>
                  <i v-if="product.wishlist" class="fas fa-heart"></i>
                  <i v-else class="fal fa-heart"></i>
                </div>
              </div>
              <router-link
                  :to="'/product/' + product.id"
                  style="color: #424242 !important"
              >
                <div
                    class="bg-black text-center  mt-3"
                >
                  <img
                      class="popular-product-image"
                      v-if="product.image"
                      :src="appURL + product.image"
                      @error="imageError"
                      alt="product image"
                      width="150"
                      height="150"
                  />
                  <img
                      v-else
                      src=""
                      class="my-3"
                      alt="default image"
                      style="
                        width: 150px;
                        height: 150px;
                        padding-left: 50px !important;
                      "
                  />
                </div>
                <p
                    class="product-name text-center m-0 p-0 mt-3"
                    style="
                      font-size: 16px !important;
                      font-weight: bold !important;
                    "
                >
                  {{ product.name }}
                </p>
                <p class="product-price text-center m-0 p-0 mt-2">
                  € {{ product.price }}
                </p>
                <div class="mt-2 text-center">
                  <button class="btn btn-buy">
                    BUY
                  </button>
                </div>
                <div class="row justify-content-center mb-3"></div>
              </router-link>
            </div>
            <div
                style="height: 150px"
                class="col-md-12 d-flex align-content-center products bg-white mx-0 my-4 p-0"
                v-for="product in productsData2"
                :key="product.id"
            >
              <div
                  class="add-wishlist"
                  @click="addToWishlist(product.id, product)"
                  style="margin-left: 70%"
              >
                <div v-if="product.updatingWishlist">
                  <div class="loader"></div>
                </div>
                <div v-else>
                  <i v-if="product.wishlist" class="fas fa-heart"></i>
                  <i v-else class="fal fa-heart"></i>
                </div>
              </div>
              <router-link
                  :to="'/product/' + product.id"
                  style="color: #424242 !important"
              >
                <div
                    class="bg-black text-center resource-image-block mt-3"
                    style="position: relative; top: 0; left: 0"
                >
                  <img
                      class="popular-product-image"
                      v-if="product.image"
                      :src="appURL + product.image"
                      @error="imageError"
                      alt="product image"
                      style="width: 100%; height: 250px; object-fit: contain"
                  />
                  <img
                      v-else
                      src=""
                      class="my-3"
                      alt="default image"
                      style="
                        width: 200px;
                        height: 200px;
                        padding-left: 50px !important;
                      "
                  />
                </div>
                <p
                    class="text-center product-name m-0 p-0 mt-3"
                    style="
                      font-size: 16px !important;
                      font-weight: bold !important;
                    "
                >
                  {{ product.name }}
                </p>
                <p class="text-center product-price m-0 p-0 mt-2">
                  € {{ product.price }}
                </p>
                <div class="mt-2 text-center">
                  <button class="btn btn-buy">
                    BUY
                  </button>
                </div>
                <div class="row justify-content-center mb-3"></div>
              </router-link>
            </div>
          </VueSlickCarousel>
        </div>
        <div v-else class="py-5">
          <div
              class="
              w-100
              d-flex
              align-items-center
              justify-content-center
              flex-column
            "
          >
            <img src="../../public/img/icons/eye-closed.svg" alt="" />
            <h3>Nothing to show here!</h3>
            <p>There are no products currently available.</p>
          </div>
        </div>
        <!-- Shop title -->
        <div class="row mb-10 pb-5">
          <div
              class="col d-flex offset-md-1 wish-title font-weight-bolder ml-10"
          >
            <p class="product-title ">Shop</p>
          </div>
          <!-- Shop Content -->

          <div class="container shop-links">
            <div class="row px-5 d-flex justify-content-between" style="margin: 0 30px">
              <div
                  class="
                  col-lg-4
                  category-vertical category-item
                  d-flex
                  flex-column
                  justify-content-center
                  category-column
                "
              >
                <router-link
                    class="text-decoration-none"
                    to="products?cat=kitchen-home"
                >
                  <KitchenHomeIcon />
                  <p class="p-1">Kitchen & Home</p>
                </router-link>
              </div>
              <div
                  class="
                  col-lg-4
                  category-horizontal
                  d-flex
                  flex-column
                  justify-content-around
                  p-0
                  category-column
                "
              >
                <div
                    class="
                    category-item category-books
                    d-flex
                    flex-column
                    justify-content-center
                  "
                    @mouseover="hover = true"
                    @mouseleave="hover = false"
                    :style="{ 'filter: saturate(2.2) !important;': hover }"
                >
                  <router-link
                      class="text-decoration-none"
                      to="products?cat=books"
                  >
                    <BookIcon />
                    <p class="p-1">Books</p>
                  </router-link>
                </div>
                <div
                    class="
                    category-item category-wellbeing
                    d-flex
                    flex-column
                    justify-content-center
                  "
                >
                  <router-link
                      class="text-decoration-none"
                      to="products?cat=well-being"
                  >
                    <WellBeingIcon />
                    <p class="p-1">Well Being</p>
                  </router-link>
                </div>
              </div>
              <div
                  class="
                  col-lg-4
                  category-horizontal
                  d-flex
                  flex-column
                  justify-content-around
                  p-0
                  category-column
                "
              >
                <div
                    class="
                    category-item category-gardening
                    d-flex
                    flex-column
                    justify-content-center
                  "
                >
                  <router-link
                      class="text-decoration-none"
                      to="products?cat=gardening"
                  >
                    <GardeningIcon />
                    <p class="p-1">Gardening</p>
                  </router-link>
                </div>
                <div
                    class="
                    category-item category-out
                    d-flex
                    flex-column
                    justify-content-center
                  "
                >
                  <router-link
                      class="text-decoration-none"
                      to="products?cat=out-about"
                  >
                    <OutAndAboutIcon />
                    <p class="p-1">Out & About</p>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Shop button with background image Section -->

      <div
          class="row mt-5 shop-background p-0"
          style="margin-left: 0px !important"
      >
        <div class="d-flex align-items-center" style="padding: 0 6.5%">
          <div class="col">
            <div
                class="
                shop-box
                d-flex
                justify-content-center
                align-items-left
                px-5
                rounded-lg
                text-left
                flex-column
              "
            >
              <h3 class="font-weight-bold text-title">
                Handcrafted and Eco-friendly
              </h3>
              <p class="text-description">
                We support local communities and individuals to ensure quality
                and fair trade.
              </p>
              <router-link
                  :to="{ path: '/products' }"
                  type="button"
                  class="btn btn-shop text-white text-nowrap p-2 btn-shop--bottom"
              >
                SHOP
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <!-- About Us Section -->
    </div>
    <section id="aboutUs">
      <div class="row mr-0 ml-0">
        <div class="container-fluid back about-us">
          <!-- About Us Title -->

          <div class="row">
            <div class="col d-flex wish-title font-weight-bolder">
              <p class="product-title text-white pt-4 pb-3">About Us</p>
            </div>
          </div>

          <!-- About us Columns -->

          <div class="row">
            <!-- 1 -->
            <div class="col-sm-12 col-lg-4">
              <div
                  class="
                  aboutus-box
                  bg-white
                  d-flex
                  align-items-center
                  px-5
                  rounded-lg
                  text-left
                  flex-column
                  justify-content-center
                "
              >
                <HelpingIcon />
                <h3 class="font-weight-bold aboutus-item pt-5 text-title">
                  Helping EU
                </h3>
                <p class="text-description">
                  EU banned, all single use plastic by 2021. Individuals, Small
                  and Medium business, lacks information on alternatives to
                  plastics.
                </p>
              </div>
            </div>
            <!-- 2 -->
            <div class="col-sm-12 col-lg-4">
              <div
                  class="
                  aboutus-box
                  bg-white
                  d-flex
                  justify-content-center
                  align-items-center
                  px-5
                  rounded-lg
                  text-left
                  flex-column
                "
              >
                <OurMissionIcon />
                <h3 class="font-weight-bold pt-5 text-title">Our Mission</h3>
                <p class="text-description">
                  A digital solution for business and individual to buy plastic
                  alternatives to create positive impact in society and the
                  planet.
                </p>
              </div>
            </div>

            <!-- 3 -->
            <div class="col-sm-12 col-lg-4">
              <div
                  class="
                  aboutus-box
                  bg-white
                  d-flex
                  justify-content-center
                  align-items-center
                  px-5
                  rounded-lg
                  text-left
                  flex-column
                "
              >
                <OurImpactIcon />
                <h3 class="font-weight-bold pt-5 text-title">Our Impact</h3>
                <p class="text-description">
                We hope to contribute in this effort by reducing the usage of plastic and encouraging the use of alternatives in the future.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer style="margin-top: -8rem" />
  </div>
</template>

<script>
import HeaderMenu from "../views/HeaderMenu";
import KitchenHomeIcon from "../components/KitchenHomeIcon";
import BookIcon from "../components/BooksIcon";
import WellBeingIcon from "../components/WellBeingIcon";
import GardeningIcon from "../components/GardeningIcon";
import OutAndAboutIcon from "../components/OutAndAboutIcon";
import HelpingIcon from "../components/HelpingIcon";
import OurImpactIcon from "../components/OurImpactIcon";
import OurMissionIcon from "../components/OurMissionIcon";
import Footer from "../components/Footer";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import CookieBanner from "../views/CookieBanner";
import axios from "axios";
import MaintenanceNotifications from "@/views/Notifications/Maintenance";
import PopupNotifications from "@/views/Notifications/Popup";
import IndividualOrBusiness from "@/views/Notifications/IndividualOrBusiness";

export default {
  components: {
    IndividualOrBusiness,
    PopupNotifications,
    MaintenanceNotifications,
    HeaderMenu,
    CookieBanner,
    VueSlickCarousel,
    KitchenHomeIcon,
    BookIcon,
    WellBeingIcon,
    GardeningIcon,
    OutAndAboutIcon,
    HelpingIcon,
    OurImpactIcon,
    OurMissionIcon,
    Footer
  },
  name: "HomePage",
  data() {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      settings: {
        "dots": false,
        "infinite": true,
        "autoplay": true,
        "autoplaySpeed": 5000,
        "speed": 500,
        "slidesToShow": 4,
        "slidesToScroll": 4,
        "initialSlide": 0,
        "responsive": [
          {
            "breakpoint": 1024,
            "settings": {
              "slidesToShow": 3,
              "slidesToScroll": 3,
              "infinite": true,
              "dots": true
            }
          },
          {
            "breakpoint": 600,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2,
              "initialSlide": 2
            }
          },
          {
            "breakpoint": 480,
            "settings": {
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          }
        ]
      },
      url: "",
      hover: false,
      products: [],
      productsData1: [],
      productsData2: [],
    };
  },
  methods: {
    additemtofav(id) {
      const newProduct = this.Products.find((product) => {
        return product.id === id;
      });

      let index = this.Products.indexOf(newProduct);

      newProduct.isFav = !newProduct.isFav;
      //this.$set(this.Products, index, newProduct)
      this.Products.splice(index, 1, newProduct);
    },
    searchKeydown: function (e) {
      if (e.keyCode === 13) {
        if (this.search.length >= 3 || this.search == "") {
          this.getProducts();
        } else {
          this.$noty.error("Please enter 3 or more characters",{ closeWith : ['button']});
        }
      }
    },
    searchInput: function () {
      if (!this.search) {
        this.getProducts();
      }
    },
    findIfInWishlist: function (id) {
      let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items"));

      if (wishlist_items == null) {
        return false;
      }

      let found = false;
      wishlist_items.forEach((itemId) => {
        if (itemId == id) {
          found = true;
          return;
        }
      });

      return found;
    },
    imageError: function (e) {
      e.target.src =
          "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png";
    },
    getProducts: function () {
      // let url = `user/popular-products?access_token=${localStorage.getItem('access_token')}`;
      let url = `user/products/all?access_token=${localStorage.getItem(
          "access_token"
      )}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      })
          .then((result) => {
            if (result) {
              // this.products = result.data.products.slice(0,4);
              const data = result.data.products.data;

              this.products = data.slice(0, 8);
              this.products.forEach((product) => {
                product.updatingWishlist = false;
              });

              if (localStorage.getItem("access_token") == null) {
                this.products.forEach((product) => {
                  if (this.findIfInWishlist(product.id)) {
                    product.wishlist = true;
                  }
                });
              }

              // just ensuring there are four products in data array
              this.productsData1 = data.slice(0, 4);
              this.productsData2 = data.slice(4, 8);
              if (this.productsData2.length < 4) {
                this.productsData2 = [
                  ...this.productsData2,
                  this.productsData1.slice(0, 4 - this.productsData2.length),
                ];
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },
    removeItem: function (arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },
    addToWishlist: function (id, product) {
      product.updatingWishlist = true;
      this.productsData1 = Object.assign({}, this.productsData1);
      this.productsData2 = Object.assign({}, this.productsData2);
      // const token = localStorage.getItem("access_token");

      // if (!token) {
        let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items"));

        if (wishlist_items == null) {
          wishlist_items = [];
        }

        if (!wishlist_items.includes(product.id)) {
          wishlist_items.push(product.id);
          localStorage.setItem(
              "wishlist_items",
              JSON.stringify(wishlist_items)
          );
          this.$noty.success('Item successfully added to wishlist',{ closeWith : ['button']})
          setTimeout(() => {
            product.wishlist = true;
            product.updatingWishlist = false;
            this.productsData1 = Object.assign({}, this.productsData1);
            this.productsData2 = Object.assign({}, this.productsData2);
          }, 500);
        } else {
          wishlist_items = this.removeItem(wishlist_items, product.id);
          localStorage.setItem(
              "wishlist_items",
              JSON.stringify(wishlist_items)
          );
          this.$logEvent(this.$analytics,"add_to_wishlist");
          this.$logEvent(this.$analytics,"user_engagement");
          this.$noty.success('Item successfully removed from wishlist',{ closeWith : ['button']})
          setTimeout(() => {
            product.wishlist = false;
            product.updatingWishlist = false;
            this.productsData1 = Object.assign({}, this.productsData1);
            this.productsData2 = Object.assign({}, this.productsData2);
          }, 500);
        }
      // }
      // else {
      //   let url = "";
      //   let method = "";
      //   if (!product.wishlist) {
      //     url = `user/wishlist/add?access_token=${localStorage.getItem(
      //         "access_token"
      //     )}&product_id=${id}`;
      //     method = "POST";
      //   } else {
      //     url = `user/wishlist/delete?access_token=${localStorage.getItem(
      //         "access_token"
      //     )}&product_id=${id}`;
      //     method = "DELETE";
      //   }
      //   axios({
      //     method: method,
      //     url: url,
      //     headers: {
      //       "content-type": "application/json",
      //     },
      //   }).then(
      //       (result) => {
      //         if (result) {
      //           product.updatingWishlist = false;
      //           this.$logEvent(this.$analytics,"add_to_wishlist");
      //           this.$logEvent(this.$analytics,"user_engagement");
      //       }
      //       },
      //       (error) => {
      //         if (error.response.status == 401) {
      //           this.$router.push({ path: "/login" });
      //         }
      //         console.log(error);
      //         product.updatingWishlist = false;
      //       }
      //   );
      //   this.getProducts();
      //
      // }
       setTimeout(() => {
            // eslint-disable-next-line no-undef
        Fire.$emit('added_to_wishlist');
        }, 500);
    },
  },
  beforeCreate() {
    localStorage.setItem('search', '')
  },
  mounted() {
    localStorage.setItem('search', '')
    this.getProducts();
    this.url = axios.defaults.baseURL;

  },
};
</script>

<style lang="scss" >
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");

.slick-prev, .slick-next {
  font-size: 0 !important;
  line-height: 0 !important;
  position: absolute !important;
  top: 50% !important;
  display: block !important;
  width: 65px !important;
  height: 113px !important;
  padding: 0 !important;
  -webkit-transform: translate(0, -50%) !important;
  transform: translate(0, -50%) !important;
  cursor: pointer !important;
  color: transparent !important;
  outline: transparent !important;
  background: transparent !important;
}
.product-list .slick-next {
  right: -20px !important;
}
.slick-prev {
  left: -50px !important;
}
.product-list .slick-next:before {
  content: "\f105" !important;
  font-family: FontAwesome !important;
  color: #143f49 !important;
  font-size: 30px !important;
}
.product-list .slick-prev:before {
  content: "\f104" !important;
  font-family: FontAwesome !important;
  color: #143f49 !important;
  font-size: 30px !important;
}
.btn-buy{
  background: #e7615a !important;
  color: white;
  border: none;
  height: 45px;
  width: 150px;
  border-radius: 2px;
  font-weight: bold;
}
.add-wishlist {
  right: 22% !important;
}

.popular-product-image {
  width: 250px;
  height: 250px;
  object-fit: cover !important;
}

.products-wrapper {
  padding: 0 5%;
  width: auto !important;
}

.products p {
  font-weight: bold !important;
  color: #143f49 !important;
}

.fa-heart {
  color: black;
  font-size: 18px;
}
.container {
  max-width: 1300px;
}
.product-title {
  font-family: "Zilla Slab";
  font-size: 32px !important;
  line-height: 45px !important;
  font-weight: bold !important;
}

.box {
  min-height: 100px;
  width: 100%;
  background-color: #457b80;
  position: fixed;
  z-index: 99;
  margin-top: -100px;
}

.category-column {
  flex-basis: 31%;
}

.category-vertical {
  min-height: 400px;
  //background: #2C656E;
  color: wheat;
  background-image: url(../assets/category-background.png) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.category-horizontal,
.category-horizontal p {
  //   background-image: url(../assets/category-background.png);
  //   background-repeat: no-repeat;
  font-size: 24px;
  font-weight: bold;
}

.category-item {
  color: white;
  height: 100%;
  border-radius: 15px;
  margin-bottom: 30px;
  box-shadow: 2px 2px 8px 0 rgba(20, 63, 73, 0.35);
}

.category-books {
  background-image: url(../assets/category-books.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.category-wellbeing {
  background-image: url(../assets/category-wellbeing.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.category-gardening {
  background-image: url(../assets/category-gardening.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
.category-out {
  background-image: url(../assets/category-out.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.category-item p {
  font-size: 24px;
  font-weight: bold;
  font-family: "Heebo";
  text-align: center;
}

.category-item {
  font-size: 24px;
  font-weight: bold;
  font-family: "Heebo";
  text-align: center;
}

.btn-shop {
  background-color: #e7615a;
  border-color: #e7615a;
  width: 200px;
  height: 40px;
  object-fit: contain;
  font-size: 14px;
  font-weight: bold;
}
.category-item p{
  margin: 0 !important;
}
.btn-shop--bottom {
  width: 128px;
}

.shop-box {
  padding-top: 35px;
  padding-bottom: 35px;
  background: white;
}

.aboutus-box {
  height: 22.75rem;
}

.shop-background {
  background-image: url(../assets/shop-background.png) !important;
  background-repeat: no-repeat !important;
  object-fit: cover !important;
  height: 24.5rem !important;
  background-size: cover !important;
  width: 100% !important;
}

.shop-background h3 {
  font-size: 1.5rem;
  font-weight: bold;
  font-family: "Heebo";
}

.about-us {
  width: 100% !important;
  background-image: url(../assets/about-us.png) !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  min-height: 40.563rem;
  padding: 0 8%;
  @media screen and (max-width:991px){
    .col-sm-12{
      margin : 12px 0px;
    }
    min-height: 89.563rem;
  }
}

.aboutus-box {
  font-family: "Heebo";
  line-height: 1.38;
}

.aboutus-box h3 {
  font-size: 1.5rem;
}

.aboutus-box p {
  font-size: 1rem;
  text-align: left;
}

.carousel-image {
  width: 100%;
}

.caption-wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}

</style>


<style >

.caption {
  position: relative;
  right: 525px;
  top: 150px;
}
.caption-title {
  font-size: 50px;
  color: #fff;
  font-family: "Zilla Slab";
  font-weight: bold;
}
.caption-description {
  font-size: 20px;
  font-weight: normal;
  color: #fff;
  font-family: Heebo;
  width: 400px !important;
}

.text-title {
  font-family: Heebo;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #424242;
}

.text-description {
  max-width: 311px !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.cookie-banner {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
}

.shop-links a {
  color: white !important;
}

.shop-links a:hover {
  color: white !important;
}

.image {
  background: transparent !important;
}

@media only screen and (max-width: 600px) {
  .popular-product-image {
    width: 150px !important;
    height: 150px !important;
    object-fit: cover !important;
  }
  .shop-box {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .category-column {
    flex-basis: 100% !important;
  }
}

.product-name a {
  font-size: 16px !important;
  font-weight: bold !important;
  color: #424242 !important;
}

.product-price {
  color: #424242 !important;
  font-size: 16px !important;
  font-weight: normal !important;
}
</style>
