<template>
  <div class="image">
    <HeaderMenu/>
    <div  >

    <div class="row  align-items-center" style="height: 100vh; width: 100%">
      <div class="col-md-4 col-sm-2 offset-md-4 card-col">
        <div  class="card bg-white shadow border-0">
          <div class="card-body p-md-5 p-sm-0">
            <div role="form-floating" style="text-align: center !important;">
              <img style="height: auto; width: 100px" src="https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"/>
              <h1 style="font-family: ZillaSlab;
                          font-size: 32px;
                          color: #143f49;
                          font-weight: bold;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.19;
                          letter-spacing: normal;
                          font-family: 'Heebo', sans-serif;">Order Successful!</h1>
              <p class="my-4" style="font-family: Heebo; font-size: 16px;
                          font-weight: normal;
                          font-stretch: normal;
                          font-style: normal;
                          line-height: 1.38;
                          letter-spacing: normal;">Your order has been received, you will receive a confirmation email once we have process your payment. Follow your order status from “My Profile” </p>
              <div v-if="newAccount" class="text-center mt-4">
                <p class="para">You account has been created and we have sent you a confirmation email along with your account details, please confirm your email to view and track orders in your account</p>
              </div>
              <div class="text-center mt-4">
                <router-link to="/products" >
                  <div class="btn btn-link login-btn mx-auto">
                    <div>Go To Shop</div>
                  </div>
                </router-link>
                <div v-if="userLoggedIn">
                  <router-link to="/profile" >
                    <div class="forgot mt-4 text-underline">
                      To Profile
                    </div>
                  </router-link>
                </div>
                <div v-else>
                  <router-link to="/signup" v-if="!newAccount">
                    <div class=" mt-4 btn btn-sm btn-dark">
                      Create an account to see order details any time
                    </div>
                  </router-link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>
<script>
// import BasePFEButton from "../components/BasePFEButton.vue";
import HeaderMenu from "../views/HeaderMenu";
import axios from 'axios';

export default {
  components: { HeaderMenu },
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      newAccount : false,
      showPassword:false,
      loading:false,
      errorMessage: "",
      userLoggedIn: true
    };
  },
  methods: {
    login: function () {
      this.loading = true;
      let url = 'contact';
      axios({
        method: "POST",
        url: url,
        data:{
          email:this.model.email,
          name:this.model.name,
          message: this.model.message
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              this.loading = false;
              alert('Contacted successfully')
              this.$router.push('/');
            }
          },
          (error) => {
            this.loading = false;
            this.errorMessage = error.response.data.message;
            console.log(error);
          }
      );
    },
  },
  mounted() {
    if(!localStorage.getItem("access_token"))
    {
      this.userLoggedIn = false
    }
    if(this.$route.query.new_account){
      this.newAccount =true;
    } else{
      this.newAccount = false
    }
  },
};
</script>
<style>
@media only screen and (max-width: 600px) {
  .card-col {
    margin-left: 18px !important;
  }
}
.image{
  background-image: url('../layout/checkout-success.png') !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

body {
  font-family: "Heebo";
  color: #424242;
}

.card {
  border-radius: 16px;
}

.forgot {
  font-family: "Heebo";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.no-account {
  /* width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px; */
  margin-top: 52px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
.sign-up {
  color: #e7615a;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  width: 460px !important;
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input,
.form-label-group label {
  height: 2.125rem;
  padding: 0.75rem;
}

.form-label-group label {
  position: absolute;
  top: -4px;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0; /* Override default `<label>` margin */
  line-height: 1.5;
  color: #b9c3bc;
  pointer-events: none;
  cursor: text; /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:-ms-input-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: 1.75rem;
}

.form-label-group input:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:-ms-input-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group input:-webkit-autofill ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group textarea::-webkit-textarea-placeholder {
  color: transparent;
}

.form-label-group textarea::-moz-placeholder {
  color: transparent;
}

.form-label-group textarea:-ms-textarea-placeholder {
  color: transparent;
}

.form-label-group textarea::-ms-textarea-placeholder {
  color: transparent;
}

.form-label-group textarea::placeholder {
  color: transparent;
}

.form-label-group textarea:not(:-moz-placeholder-shown) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group textarea:not(:-ms-textarea-placeholder) {
  padding-top: 1.25rem;
  padding-bottom: 0.25rem;
}

.form-label-group textarea:not(:placeholder-shown) {
  padding-top: 1.75rem;
}

.form-label-group textarea:not(:-moz-placeholder-shown) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group textarea:not(:-ms-textarea-placeholder) ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group textarea:not(:placeholder-shown) ~ label {
  padding-top: 0.35rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}

.form-label-group textarea:-webkit-autofill ~ label {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  font-size: 12px;
  color: #b9c3bc;
}


/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }

  .form-label-group label {
    position: static;
  }

  .form-label-group input::-ms-input-placeholder {
    color: #b9c3bc;
  }
}

.form-label-group {
  width: 292px;
}

.form-control::placeholder {
  color: red;
}

#inputUsername,#password, #message{
  font-size: 14px !important;
}

.input-group-text.border-left-0.rounded-0.rounded-right{
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: solid 1px #b9c3bc;
  background: transparent !important;
}

.password-input-group,.email-input-group{
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
}

.form-control{
  background: transparent !important;
}

h1{
  color: #424242;
}

.login-btn{
  height: 40px;
  width: 292px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  background-color: #e7615a;

  font-size:14px;
  font-family: "Heebo";
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
}

.login-btn:hover{
  color:rgba(255,255,255, 0.85);
}

.text-reset{
  text-decoration: underline;
}

.error-message{
  color: #e7615a;
  width: 292px;
}

input:-webkit-autofill,input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

</style>
