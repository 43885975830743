<template>
  <div>
    <HeaderMenu />
    <IndividualOrBusiness/>
    <div class="entry-content">
      <div class="container-fluid">
        <div
            v-if="loading"
            class="w-100 d-flex justify-content-center align-items-center"
            style="height: 50vh">
          <div class="loader" style="height: 75px; width: 75px"></div>
        </div>
        <div v-else class="container pb-3">
          <div class="top-row d-flex justify-content-between">
            <h1 class="page-title m-0">{{getCategoryName(this.search_by_category)}}</h1>
          </div>
          <div class="row align-items-end m-0">
            <div class="col-sm-2 col-md-2 d-flex p-0">
              <div class="select-div" style="max-width: 190px">
                <select
                    v-model="search_by_category"
                    class="resource-select custom-select py-0"
                    id="sort"
                    @change="goToCategories()"
                    style="width: 160px !important"
                >
                  <option value="default_category" selected>All Categories</option>
                  <option value="Kitchen & Home">Kitchen & Home</option>
                  <option value="Books">Books</option>
                  <option value="Well Being">Well Being</option>
                  <option value="Gardening">Gardening</option>
                  <option value="Out & About">Out & About</option>
                </select>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 d-flex p-0">
              <div class="select-div" style="max-width: 190px">
                <select
                    v-model="search_by_price"
                    class="resource-select custom-select py-0"
                    id="sort"
                    @change="getProducts"
                    style="width: 160px !important"
                >
                  <option value="default_price" selected>All Prices</option>
                  <option value="0">€0- €5</option>
                  <option value="5">€5- €10</option>
                  <option value="10">€10- €50</option>
                  <option value="50">€50- €100</option>
                  <option value="100">€100- €150</option>
                </select>
              </div>
            </div>
            <div class="col-sm-2 col-md-2 d-flex p-0">
              <div class="select-div" style="max-width: 190px">
                <select
                    v-model="search_by_material"
                    class="resource-select custom-select py-0"
                    id="sort"
                    @change="getProducts"
                    style="width: 160px !important"
                >
                  <option value="default_material" selected>Any Material</option>
                  <option v-for="mat in materials" :key="mat.name" :value="mat.name">{{mat.name}}</option>
                </select>
              </div>
            </div>
            <div  class="col-sm-12 col-md-2 clear-text" @click="clearFilter">
              <small>Clear All</small>
            </div>
            <div class="col-md-2 d-lg-flex justify-content-end p-0 offset-lg-2">
              <div class="select-div" style="max-width: 190px; text-align: left; color: #424242 !important;">
                <label class="resource-small-text m-0" for="sort">Sort by</label>
                <select
                    v-model="sort"
                    class="resource-select custom-select py-0"
                    id="sort"
                    @change="getProducts"
                >
                  <option value="name" selected>Name</option>
                  <option value="created_at">Newest</option>
                  <option value="created_at_desc">Oldest</option>
                  <option value="rating">Rating</option>
                  <option value="price">Price (Asc)</option>
                  <option value="price_desc">Price (Desc)</option>
                </select>
              </div>
            </div>
          </div>
          <!-- products -->
          <div class="row my-4">
            <div
                class="col-6 col-md-3 products bg-white mx-0 my-4 p-0 "
                v-for="product in products"
                :key="product.id"
            >
              <div class="add-wishlist" @click="addToWishlist(product.id,product)">
                <div v-if="product.updatingWishlist">
                  <div class="loader"></div>
                </div>
                <div v-else>
                  <i v-if="product.wishlist" class="fas fa-heart "></i>
                  <i v-else class="far fa-heart "></i>
                </div>
              </div>
              <router-link :to="'/product/' + product.id" style="color: #424242 !important;">
                <div class="bg-black resource-image-block mt-3" style="position: relative; top: 0; left: 0;">
                  <img
                      v-if="product.image"
                      :src="appURL + product.image"

                      @error="imageError"
                      alt="product image"
                      style="width: 90%; height: 250px; object-fit: contain"
                  />
                  <img v-else src=""
                       class="my-3" alt="default image" style="width: 255px; height: 255px; padding-left:50px !important;">
                </div>
                <p class="product-name-title text-center resource-large-text m-0 p-0 mt-3">
                  {{ product.name }}
                </p>
                <p class="text-center resource-large-text m-0 p-0 mt-2">
                  € {{ product.price }}
                </p>
                <div class="row justify-content-center mb-3"></div>
              </router-link>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 no-product mt-8 text-center" v-if="!total">
              <div class="w-100 d-flex align-items-center justify-content-center flex-column nothing-to-show">
                <img src="../../public/img/icons/eye-closed.svg" alt="">
                <h3 class="title">
                  Nothing to show here!
                </h3>
                <p class="content">
                  There are no products that fit your search. Try a different term or clear your filters.
                </p>
              </div>
            </div>
          </div>
          <pagination :activePage="page" style="margin-bottom: 25px;" :total="total" @fetchData="getProducts" v-if="total > 1"/>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>

import axios from "axios";
import HeaderMenu from "../views/HeaderMenu";
import Footer from "../components/Footer";
import router from "../router";
import pagination from '../components/Pagination.vue';
import IndividualOrBusiness from "@/views/Notifications/IndividualOrBusiness";
export default {
  name: "Product",
  components: {
    IndividualOrBusiness,
    HeaderMenu,
    Footer,
    pagination
  },
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      products: [],
      total: 1,
      loading: false,
      search: "",
      sort: localStorage.getItem('sortBy') || "name",
      materials: [],
      search_by_category: "default_category",
      search_by_price: localStorage.getItem('searchByPrice') || "default_price",
      search_by_material: localStorage.getItem('searchByMaterial') || "default_material",
      url: "",
      page : 1,
      categories: {
        "default_category": "",
        "Kitchen & Home": "kitchen-home",
        "Books": "books",
        "Well Being": "well-being",
        "Gardening" :"gardening",
        "Out & About": "out-about"
      }
    };
  },
  methods: {
    searchKeydown: function (e) {
      if (e.keyCode === 13) {
        if (this.search.length >= 3 || this.search == "") {
          this.getProducts();
        } else {
          this.$noty.error("Please enter 3 or more characters",{ closeWith : ['button']});
        }
      }
    },
    imageError: function(e){
      e.target.src = "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"
    },
    searchInput: function () {
      if (!this.search) {
        this.getProducts();
      }
    },
    goToCategories: function () {
      if (this.categories[this.search_by_category]){
        this.$router.push(`/products?cat=${this.categories[this.search_by_category]}`);
        this.getProducts();
      }
      else{
        this.$router.push(`/products`)
      }
    },
    // eslint-disable-next-line no-unused-vars
    addToUrl: function (key, value){
      localStorage.setItem(key, value)
      // console.log(this.$route.fullPath)
      // history.pushState(
      //     {},
      //     null,
      //     `${this.$route.fullPath}${value}`
      // );
    },
    getProducts: function (page = 1) {
      // if(page==1){
      //   let prevStatePage = localStorage.getItem('last_page');
      //   if(prevStatePage && prevStatePage > page){
      //     page = prevStatePage;
      //   }
      // }
      this.page = page;
      let url = `user/products/all?page=${page} `;
      if(localStorage.getItem("access_token")){
        url += `access_token=${localStorage.getItem("access_token")}`;
      }
      if (this.sort) {
        if (this.sort.includes("_desc")) {
          url += `&sortBy=${this.sort.split("_desc")[0]}&orderBy=DESC`;
        } else {
          url += `&sortBy=${this.sort}`;
        }
        this.addToUrl('sortBy',this.sort)
      }
      if (this.search) {
        url += `&searchBy=${this.search}`;
        this.addToUrl('searchBy', this.search)
      }
      if(this.search_by_price != 'default_price'){
        url += `&searchByPrice=${this.search_by_price}`;
        this.addToUrl('searchByPrice', this.search_by_price)
      }
      if(this.search_by_category != 'default_category'){
        url += `&searchByCategory=${this.search_by_category.replace("&", "%26")}`;
        // this.addToUrl(`&searchByCategory=${this.search_by_category}`)
      }
      if(this.search_by_material != 'default_material'){
        url += `&searchByMaterial=${this.search_by_material}`;
        this.addToUrl('searchByMaterial', this.search_by_material)
      }
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              this.products = result.data.products.data;
              this.total = Math.ceil(
                  result.data.products.total / result.data.products.per_page
              );

              let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items")) ;
              if(wishlist_items != null){
                this.products.forEach(product => {
                  wishlist_items.includes(product.id)?
                      product.wishlist = true:''
                });
              }
              this.loading = false;
              localStorage.setItem('last_page',page);
            }
          },
          (error) => {
            console.log(error);
            this.loading = false;
          }
      );
    },
    getCategoryName: function (category) {
      if (category === "default_category"){
        return "All Categories"
      }
      return category;
    },
    clearFilter: function(){
      this.search_by_category= "default_category";
      this.search_by_price= "default_price";
      this.search_by_material= "default_material";
      localStorage.setItem('search', '');
      localStorage.removeItem('searchByMaterial');
      localStorage.removeItem('sortBy');
      localStorage.removeItem('searchByPrice');
      this.search= "";
      this.getProducts();
      router.push("/products");
      this.$router.go();
    },
    removeItem: function(arr, value) {
      var i = 0;
      while (i < arr.length) {
        if (arr[i] === value) {
          arr.splice(i, 1);
        } else {
          ++i;
        }
      }
      return arr;
    },
    getMaterials: function () {
      let url = "user/materials"
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              this.materials = result.data.materials;
            }
          },
          (error) => {
            console.log(error);
            this.loading = false;
          }
      );
    },
    addToWishlist: function(id,product){
      product.updatingWishlist = true;
      this.products = Object.assign({},this.products);
      const token = localStorage.getItem("access_token");
      if(!token){
        let wishlist_items = JSON.parse(localStorage.getItem("wishlist_items")) ;

        if(wishlist_items == null){
          wishlist_items = [];
        }

        if(!wishlist_items.includes(product.id)){
          this.$noty.success('Item successfully added to wishlist',{ closeWith : ['button']})
          wishlist_items.push(product.id);
          localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));

          setTimeout(()=>{
            product.wishlist = true;
            product.updatingWishlist = false;
            this.products = Object.assign({},this.products);
          }, 500);
        }
        else{
          wishlist_items = this.removeItem(wishlist_items, product.id);
          this.$noty.success('Item successfully removed from wishlist',{ closeWith : ['button']})
          localStorage.setItem("wishlist_items", JSON.stringify(wishlist_items));

          setTimeout(()=>{
            product.wishlist = false;
            product.updatingWishlist = false;
            this.products = Object.assign({},this.products);
          }, 500);
        }
      }
      else{
        let url = '';
        let method = '';
        if(!product.wishlist){
          url = `user/wishlist/add?access_token=${localStorage.getItem('access_token')}&product_id=${id}`;
          method = 'POST';
        }
        else{
          url = `user/wishlist/delete?access_token=${localStorage.getItem("access_token")}&product_id=${id}`;
          method = 'DELETE';
        }
        axios({
          method: method,
          url: url,
          headers: {
            "content-type": "application/json",
          },
        }).then(
            (result) => {
              if (result) {
                this.$logEvent(this.$analytics,"add_to_wishlist");
                this.$logEvent(this.$analytics,"user_engagement");
                this.getProducts();
                product.updatingWishlist = false;

              }
            },
            (error) => {
              if (error.response.status == 401) {
                this.$router.push({path: "/login"})
              }
              console.log(error);
              product.updatingWishlist = false;
            }
        );
        product.updatingWishlist = false;
        this.getProducts();
      }
      // eslint-disable-next-line no-undef
      Fire.$emit('added_to_wishlist');
    }
  },
  watch: {
    $route() {
      this.$nextTick(()=>{
        // console.log(this.$route.query.cat);
      });
      const category = this.$route.query.cat;
      if(category){
        if(category == 'kitchen-home'){
          this.search_by_category = "Kitchen & Home";
        }
        else if(category == 'books'){
          this.search_by_category = "Books";
        }
        else if(category == 'well-being'){
          this.search_by_category = "Well Being";
        }
        else if(category == 'gardening'){
          this.search_by_category = "Gardening";
        }
        else if(category == 'out-about'){
          this.search_by_category = "Out & About";
        }
      }
      this.getProducts();
    }
  },
  mounted() {
    this.loading = true;
    this.url = axios.defaults.baseURL;
    this.getMaterials();
    //Selecting the Right category
    const category = this.$route.query.cat;
    if(category){
      if(category == 'kitchen-home'){
        this.search_by_category = "Kitchen & Home";
      }
      else if(category == 'books'){
        this.search_by_category = "Books";
      }
      else if(category == 'well-being'){
        this.search_by_category = "Well Being";
      }
      else if(category == 'gardening'){
        this.search_by_category = "Gardening";
      }
      else if(category == 'out-about'){
        this.search_by_category = "Out & About";
      }
    }

    //Search text
    this.search = localStorage.getItem('search');
    this.getProducts();
    this.$logEvent(this.$analytics,"view_item_list");
    this.$logEvent(this.$analytics,"user_engagement");
  },
};

</script>

<style lang="scss" scoped>

.container{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1300px !important;
  text-align: center;
}

.products{
  font-family: 'Heebo', sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.clear-text {
  text-align: left;
}

.nothing-to-show .title{
  font-size: 18px;
  font-weight: bold;
  line-height: 1.22;
  color: #424242 !important;
  opacity: 0.74;
  margin-top:25px;
}
.fa-heart{
  color: black;
}

.nothing-to-show .content{
  max-width: 345px;
  opacity: 0.74;
  line-height: 1.38;
  font-size: 16px;
  line-height: 1.38;
  color: #424242;
}

.product-name-title{
  color: #424242;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Heebo';
}
@media only screen and (max-width: 600px) {
  .resource-select, .custom-select{
    margin-top: 3px !important;
  }
  .resource-select, .custom-select,.select-div {
    min-width: 100% !important;
  }
  .clear-text {
    margin-top: 10px;
  }
}
</style>
