<template>
    <div>
       <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
            <g fill="none" fill-rule="evenodd">
                <g>
                    <g transform="translate(-610 -2376) translate(588 2359)">
                        <path d="M0 0H118V118H0z" transform="matrix(-1 0 0 1 118 0)"/>
                        <circle cx="61.982" cy="57.066" r="36.875" stroke="#143F49" stroke-width="5.5" transform="rotate(45 61.982 57.066)"/>
                        <path stroke="#143F49" stroke-linejoin="round" stroke-width="5.5" d="M48.579 56.818L62.085 32.482 74.89 56.818 62.085 81.155z" transform="rotate(45 61.735 56.818)"/>
                        <path stroke="#143F49" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.5" d="M61.982 54.607L61.982 59.524" transform="rotate(45 61.982 57.066)"/>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'books'
    }
</script>

<style lang="scss" scoped>

</style>