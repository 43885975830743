<template>
  <div>
    
      <div class="row d-flex flex-column justify-content-center align-items-center empty-list py-2 ">
          <button class="btn p-0 shadow-none fav-btn py-4"><i class="far fa-heart "></i></button>
          <p class="empty-title">Save your favorite products!</p>
          <p class="empty-message text-center w-25">You have not saved anything yet. Just click on the heart symbol next to the desired items and they will appear here.</p>
          <router-link to="/products">
            <button class="btn btn-buy mt-4">Back to Shop</button>
          </router-link>
      </div>

  </div>
</template>

<script>

export default {
  
  name: "WishlistEmpty",
};
</script>

<style lang="scss" scoped>

.empty-list{
  color:#424242;
  .fa-heart{
    color:#424242;
  }
}

.empty-list .empty-title {
    font-size: 18px;
    font-weight: bold;
}

.empty-list .empty-message {
    font-size: 16px;
    
}

.fav-btn {
    font-size: 50px;
}

.caption {
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #424242;
  font-weight: bolder;
  font-size: 24px;
}



.discount-price{
    color: #e7615a;
}

.wish-title {
  height: 116px;
  font-size: 24px;
}
.horizontal-line {
  width: 10%;
}
.btn-buy {
  width: 240px;
  background-color: #e7615a;
  color: white;
  font-size:14px;
  margin-bottom: 96px;
}

.price {
  margin-top: -15px;
}


.image-container .no-stock img{
    opacity: 0.34;
}



.image-container button {
  right: 20%;
  top: 5%;
  border-radius: 4px;
  font-size: 12px;
  padding: 0;  
}

 .no-stock img ,  .no-stock .title , .no-stock .price, .no-stock i{
    opacity: 0.34;
}

</style>
