<template>
    <div>
       <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="64px" height="60px" viewBox="0 0 64 60" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>BD8F9CE6-9577-4641-AC90-4EC491D7D003</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-614.000000, -1579.000000)">
            <g id="heart" transform="translate(596.000000, 1558.000000)">
                <rect id="Rectangle" transform="translate(49.500000, 49.000000) scale(-1, 1) translate(-49.500000, -49.000000) " x="0" y="0" width="99" height="98"/>
                <path d="M50.2927008,77.6363636 C60.8489013,71.0540894 68.5611938,64.2243613 73.4295785,57.1471792 C77.6231376,51.0598128 79.4789079,45.2199004 78.9968897,39.6274418 C78.8040823,37.1529026 78.1533577,34.8763266 77.0447156,32.7977137 C76.0324772,30.8675731 74.6587251,29.1848865 72.9234593,27.7496538 C71.2845971,26.4134026 69.4529276,25.4483323 67.4284508,24.8544429 C65.403974,24.2605535 63.3312954,24.0873358 61.210415,24.3347897 C58.2219016,24.730716 55.7636084,25.7205316 53.8355352,27.3042367 C52.2448749,28.5909971 51.0157283,30.2241929 50.1480953,32.2038243 C49.2804624,30.2241929 48.0754167,28.5909971 46.5329582,27.3042367 C44.5084814,25.7205316 42.0019863,24.730716 39.013473,24.3347897 C38.4832529,24.2358081 37.9771337,24.1863173 37.4951154,24.1863173 C35.4706386,24.1368266 33.4943636,24.4956347 31.5662905,25.2627419 C29.6382173,26.029849 27.9270524,27.1557644 26.4327958,28.6404879 C24.8421354,30.1252114 23.6370897,31.8326434 22.8176586,33.762784 C21.8536221,35.8908877 21.3234019,38.1674637 21.2269983,40.5925121 C21.034191,46.0859891 23.0345669,51.8021746 27.2281259,57.7410686 C32.1929143,64.6697783 39.7847022,71.3015433 50.0034899,77.6363636 C50.0516917,77.5868729 50.0998935,77.5621275 50.1480953,77.5621275 C50.244499,77.5621275 50.2927008,77.5868729 50.2927008,77.6363636 Z" stroke="#FFFFFF" stroke-width="5.5" stroke-linejoin="round" fill-rule="nonzero"/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'wellbeing'
    }
</script>

<style lang="scss" scoped>

</style>