<template>
    <div>
        <div style="  width: 538px;
        height: 110px;
        padding: 40px 72px 27px 56px;
        background-color: #deeddf;">
            <h2 style="text-align:center;">Shopping Cart ({{ cart_items.length }})</h2>
        </div>


        <div>
            <button style="background-color: #e7615a; width: 325px;
  height: 40px;
  margin: 36px 44px 13px 16px;
  padding: 3px 28px 1px 9px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;">GO TO CHECKOUT</button>
            <p style="text-align: center;">Shipping and taxes are calculated at checkout.</p>
            <a style="text-decoration: underline; text-align: center">CLOSE</a>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

export default {
    name: "cartItems",
    data() {
      return {
          cart_items: [],
          loading:false
      }
    },
    methods: {
        getAllCartItems: function(){
          this.loading = true;
            axios({
                method: "GET",
                url: 'user/cart', 
                params: { id:"1" },
                headers: {
                    "content-type": "application/json",
                },
            }).then(result => {
            this.isLoading = false;
            this.cart_items = result.data.cart_items;
          }, error => {
            this.isLoading = false;
            console.log(error.response);
          });
        }
    },
    mounted() {
      this.getAllCartItems();
    }
    }
</script>


<!--template>
  <div>
    <br>
    <div class="container-fluid mt--10">
      <div class="row">
        <div class="col-6 mb-5 mb-xl-0 mt--10">
        </div>
        <div class="col-6 mb-5 mb-xl-0 mt--10">
          <div class="pl-lg-4">
            <div class="order">
              <div v-for="item in cart_items" :key="item.id">
                <div class="row col-12 pt-5 pl-5 pr-5 pb-1">
                  <div class="col-1 mr-1" @onclick="deleteProduct(item.id)">
                    <img src="../../public/img/icons/close.svg" alt="" >
                  </div>
                  <div class="col-4">
                    <img :src="appURL + item.product.image" @error="imageError" alt="" height="100" width="100">
                  </div>
                  <div class="col-4">
                    <h2>{{item.product.name}}</h2>
                    <h4>{{'€ ' + item.product.price  }}</h4>
                    <div class="row"><span class="mt-2 ml-3 mr-2">Quantity:</span> <input type="text" class="form-control quantity" placeholder="10" v-model="item.quantity" @change="updateCart(item.id, item.quantity)"> </div>
                  
                  </div>
                  <div class="col-2 mt-3">
                    <h2>{{parseInt(item.product.price)* parseInt(item.quantity) || 0}}</h2>
                  </div>
                </div>
                <hr>
              </div>
              <div class="offset-5">
                <h3>Subtotal: {{total_price}}</h3>
                <h3>Tax: {{tax}}</h3>
                <h3>Shipping Charges: {{shipping_charges}}</h3>
              </div>
              <hr>
              <div class="offset-5">
                <h2>Subtotal: {{parseInt(total_price) + parseInt(tax) + parseInt(shipping_charges) || 0}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template-->
<!--script>
import axios from "axios";
export default {
  name: "Product",
  components: {},
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      profile: [],
      orders: [],
      cart_items: [],
      total: 0,
      tax: 25,
      shipping_charges: 50,
      total_price: 0,
    };
  },
  methods: {
    getCart: function () {
      let url = `user/cart?id=${localStorage.getItem('user_id')}`;
      axios({
        method: "POST",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.cart_items = result.data.cart_items;
            this.total_price = result.data.total_price;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    imageError: function(e){
      e.target.src = "https://be.plasticfreeeurope.org/emailImages/pfe-logo.png"
    },
    deleteProduct: function (id) {
      let url = `user/cart/delete/${id}?id=${localStorage.getItem('user_id')}`;
      axios({
        method: "DELETE",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getCart();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    updateProduct: function (item_id, quantity) {
      let url = `user/cart/update/${item_id}?id=${localStorage.getItem('user_id')}`;
      const data = {
        quantity: quantity,
      }
      axios({
        method: "POST",
        url: url,
        data: data,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.getCart();
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getCart();
  },
};
</script>
<style>
.title{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #424242;
  letter-spacing: normal;
}
.header{
  padding: 8px 260px 6px 30px;
  border-radius: 4px;
  background-color: #deeddf;
}

.info{
      font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.order{
  background-color: #deeddf;
  min-height: 700px;
}

.quantity{
    max-width: 50px !important;
    max-height: 40px !important;
}

.banner{
  border-radius: 4px;
  background-color: #457b80;
  min-height: 100px;
  padding: 20px;
}


.banner-text{
  font-family: Heebo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
}
</style-->