<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="72px" height="71px" viewBox="0 0 72 71" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>5B3E3D7F-50EE-40D0-9AE7-09D46A128D18</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-941.000000, -1569.000000)">
            <g id="sun" transform="translate(926.000000, 1556.000000)">
                <rect id="Rectangle-Copy-49" transform="translate(49.000000, 49.000000) scale(-1, 1) translate(-49.000000, -49.000000) " x="0" y="0" width="98" height="98"/>
                <circle id="oval" stroke="#FFFFFF" stroke-width="5.5" cx="51.3465909" cy="48.8011364" r="21.4375"/>
                <path d="M51.296875,81.15625 C69.4791245,81.15625 84.21875,66.4166245 84.21875,48.234375 C84.21875,30.0521255 69.4791245,15.3125 51.296875,15.3125 C33.1146255,15.3125 18.375,30.0521255 18.375,48.234375 C18.375,66.4166245 33.1146255,81.15625 51.296875,81.15625 Z" id="oval" stroke="#FFFFFF" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="10,19"/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'OutAndAbout'
    }
</script>

<style lang="scss" scoped>

</style>