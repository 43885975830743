<template >
  <div class="bg-white">
    
       

    <!-- start of body content -->
<HeaderMenu />
    <div class="container ">
       
      <div class="row ">
        <div class="col-md-12 ">
          <p class="head-title">
            <strong> PLASTIC FREE EUROPE PRIVACY POLICY</strong>
          </p>
            <hr>

          <p class="title"><strong>INTRODUCTION</strong></p>
          <p>
            Plastic Free Europe respects the privacy of our users. This Privacy
            Policy explains how we collect, use, disclose, and safeguard your
            information when you visit our website [plasticfreeeurope.org],
            including any other media form, media channel, mobile website, or
            mobile application related or connected thereto (collectively, the
            “Site”). Please read this privacy policy carefully.&nbsp; If you do
            not agree with the terms of this privacy policy, please do not
            access the site.
          </p>

          <p>
            We reserve the right to make changes to this Privacy Policy at any
            time and for any reason.&nbsp; We will alert you about any changes
            by updating the “Last Updated” date of this Privacy Policy.&nbsp;
            Any changes or modifications will be effective immediately upon
            posting the updated Privacy Policy on the Site, and you waive the
            right to receive specific notice of each such change or
            modification.
          </p>

          <p>
            You are encouraged to periodically review this Privacy Policy to
            stay informed of updates. You will be deemed to have been made aware
            of, will be subject to, and will be deemed to have accepted the
            changes in any revised Privacy Policy by your continued use of the
            Site after the date such revised Privacy Policy is posted.
          </p>

          <br />
          <br />
          <p class="collection">
            <strong>COLLECTION OF YOUR INFORMATION</strong>
          </p>

          <p>
            We may collect information about you in a variety of ways. The
            information we may collect on the Site includes:
          </p>

          <h2>
            <span style="font-size: 14pt;"><strong>Personal Data</strong></span>
          </h2>

          <p>
            Personally identifiable information, such as your name, shipping
            address, email address, and telephone number, and demographic
            information, such as your age, gender, hometown, and interests, that
            you voluntarily give to us when you register with the Site, or when
            you choose to participate in various activities related to the Site.
            You are under no obligation to provide us with personal information
            of any kind, however your refusal to do so may prevent you from
            using certain features of the Site.
          </p>

          <h2>
            <span style="font-size: 14pt;"
              ><strong>Derivative Data</strong></span
            >
          </h2>

          <p>
            Information our servers automatically collect when you access the
            Site, such as your IP address, your browser type, your operating
            system, your access times, and the pages you have viewed directly
            before and after accessing the Site. (If you are using our mobile
            application, this information may also include your device name and
            type, your operating system, your phone number, your country and
            other interactions with the application via server log files, as
            well as any other information you choose to provide.)
          </p>

          <h2>
            <span style="font-size: 14pt;"
              ><strong>Financial Data</strong></span
            >
          </h2>
          <p>
            Financial information, such as data related to your payment method
            (e.g. valid credit card number, card brand, expiration date) that we
            may collect when you purchase, order, return, exchange, or request
            information about our services from the Site. (We store only very
            limited, if any, financial information that we collect. Otherwise,
            all financial information is stored by our payment processor,
            <a href="https://stripe.com/us/privacy/">Stripe,</a> and you are
            encouraged to review their privacy policy and contact them directly
            for responses to your questions.)
          </p>

          <h2>
            <span style="font-size: 14pt;"
              ><strong>Mobile Device Data</strong></span
            >
          </h2>

          <p>
            Device information, such as your mobile device ID, model, and
            manufacturer, and information about the location of your device, if
            you access the Site from a mobile device.
          </p>

          <h2>
            <span style="font-size: 14pt;"
              ><strong>Third-Party Data</strong></span
            >
          </h2>

          <p>
            Information from third parties, such as personal information or
            network friends, if you connect your account to the third party and
            grant the Site permission to access this information.
          </p>

          <h2>
            <strong
              ><span style="font-size: 14pt;"
                >Data From Contests, Giveaways, and Surveys</span
              ></strong
            >
          </h2>
          <p>
            Personal and other information you may provide when entering
            contests or giveaways and/or responding to surveys.
          </p>

          <h2>
            <strong
              ><span style="font-size: 14pt;"
                >Mobile Application Information</span
              ></strong
            >
          </h2>
          
            <p>If you connect using our mobile application:</p>
            <ul>
              <li>
                <em>Geo-Location Information.</em> We may request access or
                permission to and track location-based information from your
                mobile device, either continuously or while you are using our
                mobile application, to provide location-based services. If you
                wish to change our access or permissions, you may do so in your
                device’s settings.
              </li>
              <li>
                <em>Mobile Device Access.</em> We may request access or
                permission to certain features from your mobile device,
                including your mobile device’s [bluetooth, calendar, camera,
                contacts, microphone, reminders, sensors, SMS messages, social
                media accounts, storage,] and other features. If you wish to
                change our access or permissions, you may do so in your device’s
                settings.
              </li>
              <li>
                <em>Mobile Device Data.</em> We may collect device information
                (such as your mobile device ID, model and manufacturer),
                operating system, version information and IP address.
              </li>
              <li>
                <em>Push Notifications. </em>We may request to send you push
                notifications regarding your account or the Application. If you
                wish to opt-out from receiving these types of communications,
                you may turn them off in your device’s settings.
              </li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <strong
                ><span style="font-size: 16pt;"
                  >USE OF YOUR INFORMATION</span
                ></strong
              >
            </h1>
            <p>
              Having accurate information about you permits us to provide you
              with a smooth, efficient, and customized experience.&nbsp;
              Specifically, we may use information collected about you via the
              Site to:
            </p>
            <ul>
              <li>Administer sweepstakes, promotions, and contests.</li>
              <li>Assist law enforcement and respond to subpoena.</li>
              <li>
                Compile anonymous statistical data and analysis for use
                internally or with third parties.
              </li>
              <li>Create and manage your account.</li>
              <li>
                Deliver targeted advertising, coupons, newsletters, and other
                information regarding promotions and the Site to you.
              </li>
              <li>Email you regarding your account or order.</li>
              <li>Enable user-to-user communications.</li>
              <li>
                Fulfill and manage purchases, orders, payments, and other
                transactions related to the Site.
              </li>
              <li>
                Generate a personal profile about you to make future visits to
                the Site more personalized.
              </li>
              <li>Increase the efficiency and operation of the Site.</li>
              <li>
                Monitor and analyze usage and trends to improve your experience
                with the Site.
              </li>
              <li>Notify you of updates to the Site.</li>
              <li>
                Offer new products, services, and/or recommendations to you.
              </li>
              <li>Perform other business activities as needed.</li>
              <li>
                Prevent fraudulent transactions, monitor against theft, and
                protect against criminal activity.
              </li>
              <li>Process payments and refunds.</li>
              <li>
                Request feedback and contact you about your use of the Site.
              </li>
              <li>Resolve disputes and troubleshoot problems.</li>
              <li>Respond to product and customer service requests.</li>
              <li>Send you a newsletter.</li>
              <li>Solicit support for the Site.</li>
            </ul>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <span style="font-size: 16pt;"
                ><strong>DISCLOSURE OF YOUR INFORMATION</strong></span
              >
            </h1>
            <p>
              We may share information we have collected about you in certain
              situations. Your information may be disclosed as follows:
            </p>
            <h2>
              <strong
                ><span style="font-size: 14pt;"
                  >By Law or to Protect Rights</span
                ></strong
              >
            </h2>
            <p>
              If we believe the release of information about you is necessary to
              respond to legal process, to investigate or remedy potential
              violations of our policies, or to protect the rights, property,
              and safety of others, we may share your information as permitted
              or required by any applicable law, rule, or regulation.&nbsp; This
              includes exchanging information with other entities for fraud
              protection and credit risk reduction.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Third-Party Service Providers</strong></span
              >
            </h2>
            <p>
              We may share your information with third parties that perform
              services for us or on our behalf, including payment processing,
              data analysis, email delivery, hosting services, customer service,
              and marketing assistance.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Marketing Communications</strong></span
              >
            </h2>
            <p>
              With your consent, or with an opportunity for you to withdraw
              consent, we may share your information with third parties for
              marketing purposes, as permitted by law.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Third-Party Advertisers</strong></span
              >
            </h2>
            <p>
              We may use third-party advertising companies to serve ads when you
              visit the Site [or our mobile application]. These companies may
              use information about your visits to the Site [and our mobile
              application] and other websites that are contained in web cookies
              in order to provide advertisements about goods and services of
              interest to you.
            </p>
            <h2>
              <strong><span style="font-size: 14pt;">Affiliates</span></strong>
            </h2>
            <p>
              We may share your information with our affiliates, in which case
              we will require those affiliates to honor this Privacy Policy.
              Affiliates include our parent company and any subsidiaries, joint
              venture partners or other companies that we control or that are
              under common control with us.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Business Partners</strong></span
              >
            </h2>
            <p>
              We may share your information with our business partners to offer
              you certain products, services or promotions.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Offer Wall&nbsp;</strong></span
              >
            </h2>
            <p>
              Our mobile application may display a third-party hosted “offer
              wall.”&nbsp; Such an offer wall allows third-party advertisers to
              offer virtual currency, gifts, or other items to users in return
              for acceptance and completion of an advertisement offer.&nbsp;
              Such an offer wall may appear in our mobile application and be
              displayed to you based on certain data, such as your geographic
              area or demographic information.&nbsp; When you click on an offer
              wall, you will leave our mobile application.&nbsp; A unique
              identifier, such as your user ID, will be shared with the offer
              wall provider in order to prevent fraud and properly credit your
              account.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Other Third Parties</strong></span
              >
            </h2>
            <p>
              We may share your information with advertisers and investors for
              the purpose of conducting general business analysis. We may also
              share your information with such third parties for marketing
              purposes, as permitted by law.
            </p>
            <h2>
              <strong
                ><span style="font-size: 14pt;"
                  >Sale or Bankruptcy</span
                ></strong
              >
            </h2>
            <p>
              If we reorganize or sell all or a portion of our assets, undergo a
              merger, or are acquired by another entity, we may transfer your
              information to the successor entity.&nbsp; If we go out of
              business or enter bankruptcy, your information would be an asset
              transferred or acquired by a third party.&nbsp; You acknowledge
              that such transfers may occur and that the transferee may decline
              honor commitments we made in this Privacy Policy.
            </p>
            <p>
              We are not responsible for the actions of third parties with whom
              you share personal or sensitive data, and we have no authority to
              manage or control third-party solicitations.&nbsp; If you no
              longer wish to receive correspondence, emails or other
              communications from third parties, you are responsible for
              contacting the third party directly.
            </p>
            <p>&nbsp;</p>
            <h1>
              <strong
                ><span style="font-size: 16pt;"
                  >TRACKING TECHNOLOGIES</span
                ></strong
              >
            </h1>
            <h2>
              <strong
                ><span style="font-size: 14pt;"
                  >Cookies and Web Beacons</span
                ></strong
              >
            </h2>
            <p>
              We may use cookies, web beacons, tracking pixels, and other
              tracking technologies on the Site to help customize the Site and
              improve your experience. When you access the Site, your personal
              information is not collected through the use of tracking
              technology. Most browsers are set to accept cookies by default.
              You can remove or reject cookies, but be aware that such action
              could affect the availability and functionality of the Site. You
              may not decline web beacons. However, they can be rendered
              ineffective by declining all cookies or by modifying your web
              browser’s settings to notify you each time a cookie is tendered,
              permitting you to accept or decline cookies on an individual
              basis.
            </p>
            <p>
              We may use cookies, web beacons, tracking pixels, and other
              tracking technologies on the Site to help customize the Site and
              improve your experience. For more information on how we use
              cookies, please refer to our Cookie Policy posted on the Site,
              which is incorporated into this Privacy Policy. By using the Site,
              you agree to be bound by our Cookie Policy.
            </p>
            <h2>
              <span style="font-size: 14pt;"
                ><strong>Internet-Based Advertising</strong></span
              >
            </h2>
            <p>
              Additionally, we may use third-party software to serve ads on the
              Site, implement email marketing campaigns, and manage other
              interactive marketing initiatives.&nbsp; This third-party software
              may use cookies or similar tracking technology to help manage and
              optimize your online experience with us.&nbsp; For more
              information about opting-out of interest-based ads, visit the
              <a href="http://www.networkadvertising.org/choices/"
                >Network Advertising Initiative Opt-Out Tool</a
              >
              or
              <a href="http://www.aboutads.info/choices/"
                >Digital Advertising Alliance Opt-Out Tool</a
              >.
            </p>
            <h2>
              <strong
                ><span style="font-size: 14pt;">Website Analytics</span></strong
              >
            </h2>
            <p>
              We may also partner with selected third-party vendors[, such as
              [<a href="http://www.adobe.com/privacy/marketing-cloud.html"
                >Adobe Analytics</a
              >,] [<a href="https://www.clicktale.com/company/privacy-policy/"
                >Clicktale,</a
              >] [<a href="https://clicky.com/terms">Clicky</a>,] [<a
                href="https://www.cloudflare.com/security-policy/"
                >Cloudfare</a
              >,] [<a href="https://www.crazyegg.com/privacy/">Crazy Egg,</a>]
              [<a
                href="https://policies.yahoo.com/us/en/yahoo/privacy/products/developer/index.htm"
                >Flurry Analytics,</a
              >] [<a
                href="https://support.google.com/analytics/answer/6004245?hl=en"
                >Google Analytics</a
              >,] [<a href="https://heapanalytics.com/privacy">Heap Analytics</a
              >,] [<a href="https://www.inspectlet.com/legal#privacy"
                >Inspectlet,</a
              >] [<a href="https://signin.kissmetrics.com/privacy"
                >Kissmetrics,]</a
              >
              [<a href="https://mixpanel.com/privacy/">Mixpanel,</a>] [<a
                href="https://piwik.org/privacy/"
                >Piwik,</a
              >] and others], to allow tracking technologies and remarketing
              services on the Site through the use of first party cookies and
              third-party cookies, to, among other things, analyze and track
              users’ use of the Site, determine the popularity of certain
              content and better understand online activity. By accessing the
              Site, you consent to the collection and use of your information by
              these third-party vendors. You are encouraged to review their
              privacy policy and contact them directly for responses to your
              questions. We do not transfer personal information to these
              third-party vendors. However, if you do not want any information
              to be collected and used by tracking technologies, you can visit
              the third-party vendor or the
              <a href="http://www.networkadvertising.org/choices/"
                >Network Advertising Initiative Opt-Out Tool</a
              >
              or
              <a href="http://www.aboutads.info/choices/"
                >Digital Advertising Alliance Opt-Out Tool</a
              >.
            </p>
            <p>
              You should be aware that getting a new computer, installing a new
              browser, upgrading an existing browser, or erasing or otherwise
              altering your browser’s cookies files may also clear certain
              opt-out cookies, plug-ins, or settings.
            </p>
            <h1>
              <strong
                ><span style="font-size: 16pt;"
                  >THIRD-PARTY WEBSITES</span
                ></strong
              >
            </h1>
            <p>
              The Site may contain links to third-party websites and
              applications of interest, including advertisements and external
              services, that are not affiliated with us. Once you have used
              these links to leave the Site, any information you provide to
              these third parties is not covered by this Privacy Policy, and we
              cannot guarantee the safety and privacy of your information.
              Before visiting and providing any information to any third-party
              websites, you should inform yourself of the privacy policies and
              practices (if any) of the third party responsible for that
              website, and should take those steps necessary to, in your
              discretion, protect the privacy of your information. We are not
              responsible for the content or privacy and security practices and
              policies of any third parties, including other sites, services or
              applications that may be linked to or from the Site.
            </p>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <strong
                ><span style="font-size: 16pt;"
                  >SECURITY OF YOUR INFORMATION</span
                ></strong
              >
            </h1>
            <p>
              We use administrative, technical, and physical security measures
              to help protect your personal information.&nbsp; While we have
              taken reasonable steps to secure the personal information you
              provide to us, please be aware that despite our efforts, no
              security measures are perfect or impenetrable, and no method of
              data transmission can be guaranteed against any interception or
              other type of misuse.&nbsp; Any information disclosed online is
              vulnerable to interception and misuse by unauthorized parties.
              Therefore, we cannot guarantee complete security if you provide
              personal information.
            </p>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <strong
                ><span style="font-size: 16pt;"
                  >POLICY FOR CHILDREN</span
                ></strong
              >
            </h1>
            <p>
              We do not knowingly solicit information from or market to children
              under the age of 13. If you become aware of any data we have
              collected from children under age 13, please contact us using the
              contact information provided below.
            </p>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <span style="font-size: 16pt;"
                ><strong>CONTROLS FOR DO-NOT-TRACK FEATURES&nbsp;</strong></span
              >
            </h1>
            <p>
              Most web browsers and some mobile operating systems include a
              Do-Not-Track (“DNT”) feature or setting you can activate to signal
              your privacy preference not to have data about your online
              browsing activities monitored and collected.&nbsp; No uniform
              technology standard for recognizing and implementing DNT signals
              has been finalized. As such, we do not currently respond to DNT
              browser signals or any other mechanism that automatically
              communicates your choice not to be tracked online.&nbsp; If a
              standard for online tracking is adopted that we must follow in the
              future, we will inform you about that practice in a revised
              version of this Privacy Policy./Most web browsers and some mobile
              operating systems include a Do-Not-Track (“DNT”) feature or
              setting you can activate to signal your privacy preference not to
              have data about your online browsing activities monitored and
              collected. If you set the DNT signal on your browser, we will
              respond to such DNT browser signals.
            </p>
            <p>&nbsp;</p>
            <h1>
              <span style="font-size: 16pt;"
                ><strong>OPTIONS REGARDING YOUR INFORMATION</strong></span
              >
            </h1>
            <h2>
              <strong
                ><span style="font-size: 14pt;"
                  >Account Information</span
                ></strong
              >
            </h2>
            <p>
              You may at any time review or change the information in your
              account or terminate your account by:
            </p>
            <ul>
              <li>
                Logging into your account settings and updating your account
              </li>
              <li>
                Contacting us using the contact information provided below
              </li>
            </ul>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, some information may be retained in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with legal requirements.
            </p>
            <h2>
              <strong
                ><span style="font-size: 14pt;"
                  >Emails and Communications</span
                ></strong
              >
            </h2>
            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from us, you may opt-out by:
            </p>
            <ul>
              <li>
                Contacting us using the contact information provided below
              </li>
            </ul>
            <p>
              If you no longer wish to receive correspondence, emails, or other
              communications from third parties, you are responsible for
              contacting the third party directly.
            </p>
            <p>&nbsp;</p>
            <h1>
              <span style="font-size: 16pt;"
                ><strong>CALIFORNIA PRIVACY RIGHTS</strong></span
              >
            </h1>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine
              The Light” law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
            </p>
            <p>
              If you are under 18 years of age, reside in California, and have a
              registered account with the Site, you have the right to request
              removal of unwanted data that you publicly post on the Site. To
              request removal of such data, please contact us using the contact
              information provided below, and include the email address
              associated with your account and a statement that you reside in
              California.&nbsp; We will make sure the data is not publicly
              displayed on the Site, but please be aware that the data may not
              be completely or comprehensively removed from our systems.
            </p>
            <p><strong>&nbsp;</strong></p>
            <h1>
              <strong><span style="font-size: 16pt;">CONTACT US</span></strong>
            </h1>
            <p>
              If you have questions or comments about this Privacy Policy,
              please contact us at:
            </p>
            <p>Plastic Free Europe</p>
            <p>Stockholm</p>
            <p>
              <a href="mailto:contact@plasticfreeeurope.org"
                >contact@plasticfreeeurope.org</a
              >
            </p>
          
        </div>
      </div>
     
    
    </div>
 <Footer/>
    <!-- end of body content -->

  </div>
</template>

<script>
import HeaderMenu from "../views/HeaderMenu";
import Footer from "../components/Footer.vue";

export default {
  components: {HeaderMenu , Footer},
  name: "privacy",
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");

p {
  font-family: "Heebo", sans-serif;
  color: #424242;
}

.collection {
  font-size: 16pt;
  font-weight: 700;
}

.head-title {
  //background-color: black;

  font-size: 24pt;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
}

.title {
  //background-color: black;
  font-size: 16pt;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
}
h2 , h1, h3, h4 , h5 {
  font-family: "Heebo", sans-serif;
}
</style>
