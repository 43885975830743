<template>
  <div class="container-fluid p-0" v-if="m_notifications_closed === 'false'  && this.notifications.length > 0">
    <div class="row">
      <div class="col-md-12">
        <div  class="alert alert-warning alert-dismissible fade show m-0"  role="alert">
          <span v-for="notification in notifications" :key="notification.id">
            {{ notification.description }} <br>
          </span>
          <button type="button" class="close" @click="removeNotification()" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "MaintenanceNotifications",
  data: function () {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      isLoading: false,
      m_notifications_closed: localStorage.getItem('m_notifications_closed') || "false",
      notifications: JSON.parse(localStorage.getItem('m_notifications')) || []
    };
  },
  methods: {
    removeNotification(){
      // let notifications = JSON.parse(localStorage.getItem('m_notifications'))
      // notifications = notifications.filter((brand) => brand.id !== id)
      // localStorage.setItem('m_notifications', JSON.stringify(notifications))
      // localStorage.setItem('m_notifications',null)
      localStorage.setItem('m_notifications_closed', "true")
    },
    getAllNotifications: function () {
      // if (this.m_notifications_closed === "false"){
        this.isLoading = true;
        axios.get("notifications/maintenance")
            .then(result => {
              this.isLoading = false;
              const stringData = JSON.stringify(result.data)
              const existingData = localStorage.getItem('m_notifications')
              const checkIfSameData = stringData === existingData

              if (checkIfSameData === false){
                localStorage.setItem('m_notifications_closed', "false")
                this.m_notifications_closed = "false"
              }
              localStorage.setItem('m_notifications', stringData)
              this.notifications = result.data;
            }, error => {
              this.isLoading = false;
              localStorage.setItem('m_notifications_closed', "false")
              console.log(error.response);
            });
      // }
    },
  },
  mounted() {
    this.getAllNotifications()
  },

};
</script>

<style lang="scss" scoped>
.alert{
  background-color: #e7615a !important;
  color: #FFFFFF;
  border-radius: 0;
  border: none
}
</style>
