<template>
  <div class="modal fade" id="choiceModal"  data-backdrop="false" tabindex="-1" aria-labelledby="choiceModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body">
          <!--          <div class="text-right">
                      <button @click="removeNotification" type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>-->
          <div>
            <div class="text-center">
              <h3 class="text-white display-4 font-weight-300">Shopping as?</h3>
            </div>
            <div class="btn-group btn-group-toggle row d-flex align-items-center align-content-center" data-toggle="buttons">
              <label :class="`btn btn-secondary ${individualOrBusiness === 'individual' ? 'active': ''}`">
                <input type="radio" @click="saveChoice('individual')" value="individual" v-model="individualOrBusiness" name="options" id="individual"> Individual
              </label>
              <label class="p-2">
                OR
              </label>
              <label :class="`btn btn-secondary ${individualOrBusiness === 'business' ? 'active': ''}`" >
                <input type="radio" @click="saveChoice('business')" value="business" v-model="individualOrBusiness"  name="options" id="business"> Business
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividualOrBusiness",
  data: function () {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      isLoading: true,
      individualOrBusiness: sessionStorage.getItem('individual_or_business') || null,
      individualOrBusinessPopupClosed: sessionStorage.getItem('individual_or_business_popup_closed') || "false"
    };
  },
  methods: {
    saveChoice(value){
      sessionStorage.setItem('individual_or_business', value)
      // eslint-disable-next-line no-undef
      Fire.$emit('individual_or_business_selected',value);
      this.removeNotification()
    },
    removeNotification(){
      // eslint-disable-next-line no-undef
      $("#choiceModal").modal('hide');
      sessionStorage.setItem('individual_or_business_popup_closed', "true")
    },
    showModal() {
      if (this.individualOrBusinessPopupClosed === 'false')
          // eslint-disable-next-line no-undef
        $("#choiceModal").modal('show');
    }
  },
  mounted() {
    this.showModal();
    // eslint-disable-next-line no-undef
    Fire.$on("toggle_shopping_as", () => {
      // eslint-disable-next-line no-undef
      $("#choiceModal").modal('show');
    });
  },

};
</script>

<style lang="scss" scoped>
@media (min-width: 576px){
  .modal-dialog {
    max-width: 500px;
    margin-top: 17.75rem !important;
    margin-bottom: 17.75rem !important;
    margin-left: auto !important;
  }
}
@media only screen and (max-width: 600px) {
  .modal-dialog {
    margin: 17.75rem 1rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .modal-dialog {
    max-width: 500px;
    margin-top: 17.75rem !important;
    margin-bottom: 17.75rem !important;
  }
}
.modal-footer,.modal-header,.modal-body{
  background-color: #e7615a !important;
  color: #FFFFFF;
}
.active {
  color: #FFFFFF !important;
  background: #212529 !important;
}
</style>
