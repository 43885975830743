<template>
    <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49px" height="70px" viewBox="0 0 49 70" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>0A65D6B9-82CB-4A74-B622-97348D109841</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-956.000000, -1353.000000)">
            <g id="eco" transform="translate(928.000000, 1337.000000)">
                <rect id="Rectangle-Copy-53" transform="translate(49.000000, 49.000000) scale(-1, 1) translate(-49.000000, -49.000000) " x="0" y="0" width="98" height="98"/>
                <path d="M34.4491964,42.5692361 C34.4491964,29.9022094 41.9888066,18.996153 52.8251444,14.0939493 C63.6615126,18.9988878 71.1992322,29.9037976 71.1992322,42.5692361 C71.1992322,55.2346747 63.6615126,66.1395845 52.82736,71.0426789 C41.9888066,66.1423193 34.4491964,55.2362628 34.4491964,42.5692361 Z" id="oval" stroke="#FFFFFF" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round" transform="translate(52.824214, 42.568314) rotate(39.000000) translate(-52.824214, -42.568314) "/>
                <path d="M37.2826802,41.5849207 C34.2489844,62.3911029 38.2446365,76.2341831 49.2696365,83.1141613" id="line" stroke="#FFFFFF" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round" transform="translate(42.785182, 62.349541) rotate(39.000000) translate(-42.785182, -62.349541) "/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'gardening'
    }
</script>

<style lang="scss" scoped>

</style>