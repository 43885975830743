<template>
    <div>
       <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="85px" height="81px" viewBox="0 0 85 81" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>687D9222-DA55-41D2-8B8C-38EFB3418162</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-232.000000, -2376.000000)">
            <g id="star-copy" transform="translate(214.000000, 2359.000000)">
                <rect id="Rectangle" transform="translate(59.000000, 59.000000) scale(-1, 1) translate(-59.000000, -59.000000) " x="0" y="0" width="118" height="118"/>
                <path d="M38.7439549,95 C39.4965632,95 40.2491715,94.8125 41.0017799,94.4375 L41.0017799,94.4375 L58.5939992,86.28125 C59.0957381,86.03125 59.7229117,85.90625 60.47552,85.90625 C61.2908457,85.90625 61.9493779,86.03125 62.4511168,86.28125 L62.4511168,86.28125 L79.8551841,94.4375 C82.1757264,95.375 84.0258885,95.09375 85.4056704,93.59375 C86.2837135,92.59375 86.6286589,91.28125 86.4405069,89.65625 L86.4405069,89.65625 L84.0886059,70.53125 C84.0258885,69.96875 84.1199646,69.34375 84.370834,68.65625 C84.6217034,67.84375 84.9352902,67.25 85.3115944,66.875 L85.3115944,66.875 L98.4822399,52.90625 C99.8620218,51.34375 100.301043,49.78125 99.7993044,48.21875 C99.1721309,46.53125 97.886425,45.53125 95.9421868,45.21875 L95.9421868,45.21875 L76.9388269,41.5625 C76.4998054,41.5 75.9510285,41.234375 75.2924962,40.765625 C74.6339639,40.296875 74.1479044,39.84375 73.8343176,39.40625 L73.8343176,39.40625 L64.9911699,23.28125 C64.6775831,22.65625 64.2385616,22.03125 63.6741053,21.40625 C62.7333449,20.46875 61.6671498,20 60.47552,20 C58.6567166,20 57.2142173,21.03125 56.1480222,23.09375 L56.1480222,23.09375 L47.0226464,39.40625 C46.8344943,39.84375 46.4111521,40.296875 45.7526198,40.765625 C45.0940876,41.234375 44.4825933,41.5 43.9181371,41.5625 L43.9181371,41.5625 L25.0088532,45.21875 C22.9391803,45.53125 21.6848331,46.53125 21.2458116,48.21875 C20.6813554,49.84375 21.0890182,51.40625 22.4688001,52.90625 L22.4688001,52.90625 L35.7335216,66.875 C36.0471084,67.3125 36.3293366,67.90625 36.580206,68.65625 C36.8937928,69.40625 36.9878688,70.03125 36.8624341,70.53125 L36.8624341,70.53125 L34.4164571,89.65625 C34.2910224,91.28125 34.6673265,92.59375 35.5453696,93.59375 C36.3606952,94.53125 37.4268904,95 38.7439549,95 Z" id="star" stroke="#143F49" stroke-width="5.5" fill-rule="nonzero"/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'books'
    }
</script>

<style lang="scss" scoped>

</style>