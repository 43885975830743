<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-6">
        <div class="card bg-white shadow border-0">
          <div class="card-body">
            <form role="form-floating">
              <p class="main-title">Log In</p>
              <p style="color: red">{{ confirm_email_message }}</p>
                <div class="email-input-group mx-auto input-group-alternative mb-3 ">
                  <input
                    name="email"
                    type="email"
                    id="inputEmail"
                    class="form-control border-silver "
                    v-validate="'required|email'"
                    placeholder="Email address"
                    required=""
                    v-model="model.email"
                    autofocus=""
                    :class="errors.has('email')? 'is-invalid' : ''"

                    />
                </div>
              <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>

                <div
                    class="password-input-group input-group input-group-alternative mx-auto border-0 border-right-0"
                >
                  <input class="form-control border-0" :type="inputType" id="password"
                         placeholder="Password"
                         addon-right-icon="fa fa-eye"
                         v-model="model.password"
                         required=""
                         autofocus=""
                         name="password"
                         v-validate="'required|min:6'"
                         :style="[errors.has('password') ? {'color': '#e74c3c !important','border': 'solid 1px #e74c3c !important'} : {}]"
                         @keyup.enter="login"
                  >
                  <div class="input-group-prepend m-2">
                    <span v-if="!passwordShow" class="eye-icon cursor-pointer" @click="hidePassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                    <span v-else class="eye-icon cursor-pointer" @click="showPassword"><i class="fas fa-eye-slash"></i></span>
                  </div>
              </div>
              <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>

              <div v-if="errorMessage" class="mx-auto error-message">
                {{errorMessage}}
              </div>

              <div class="text-center mt-4">
                <div class="btn btn-link login-btn mx-auto" @click="login">
                  <div class="loader mx-auto" v-if="loading"></div>
                  <div v-else>LOG IN</div>
                </div>

                <router-link to="/reset-password" >
                  <div class="forgot mt-4 text-underline">
                    Forgot Password?
                  </div>
                </router-link>
                <div class="no-account">
                  Don't have an account? <span class="sign-up"><router-link to="/signup" class="text-reset">Sign Up!</router-link></span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import BasePFEButton from "../components/BasePFEButton.vue";
import axios from 'axios';

export default {
  // components: { BasePFEButton },
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      confirm_email_message:"",
      loading:false,
      errorMessage: "",
      passwordShow: false,
      inputType: 'password',
    };
  },
  methods: {
    login: function () {
      this.$validator.validateAll().then((result) => {
      if (result) {
      this.loading = true;
      let url = 'user/login';

      const offline_wishlist_items = JSON.parse(localStorage.getItem("wishlist_items"));
      const offline_cart_items = JSON.parse(localStorage.getItem("cartItems"));

      axios({
        method: "POST",
        url: url,
        data:{
          email:this.model.email,
          password:this.model.password,
          offline_wishlist_items : offline_wishlist_items,
          offline_cart_items : offline_cart_items,
        },
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.loading = false;
            localStorage.setItem('access_token', result.data.auth.access_token);
            localStorage.setItem('access_token_expires_at', result.data.auth.expires_at);
            localStorage.setItem('name', result.data.user.name);
            localStorage.setItem('email', result.data.user.email);
            localStorage.setItem('user_id', result.data.user.id);
            localStorage.removeItem('confirm_email_message');
            localStorage.removeItem("wishlist_items");
            localStorage.removeItem("cartItems");
            location.assign('/');
          }
        },
        (error) => {
          this.loading = false;
          this.errorMessage = error.response.data.message;
          console.log(error);
        }
      );
      }
      }).catch(() => {});
    },
    showPassword: function(){
      this.passwordShow = false;
      this.inputType = 'password';
    },
    hidePassword: function(){
      this.passwordShow = true;
      this.inputType = 'text';
    }
  },
  beforeCreate() {
    localStorage.setItem('search', '')
  },
  mounted() {
    this.confirm_email_message = localStorage.getItem('confirm_email_message');
    localStorage.removeItem('confirm_email_message')
  }
};
</script>
<style>
body {
  font-family: "Heebo";
  color: #424242;
}

.card {
  min-width: 560px;
  border-radius: 16px;
}

.forgot {
  font-family: "Heebo";
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.no-account {
  /* width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px; */
  margin-top: 52px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}
.sign-up {
  color: #e7615a;
}

.form-signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: auto;
}

.form-label-group {
  width: 300px !important;
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group input::-webkit-input-placeholder{
  color: #b9c3bc !important;
}



.form-control::placeholder {
  color: red;
}

#inputEmail,#password{
  font-size: 14px !important;
}

.input-group-text.border-left-0.rounded-0.rounded-right{
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: 0px !important;
  border-right: solid 1px #b9c3bc !important;
  background: transparent !important;
}

.password-input-group,.email-input-group{
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
}

.form-control{
  background: transparent !important;
}

h1{
  color: #424242;
}

.login-btn{
  height: 40px;
  width: 300px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  background-color: #e7615a;
  font-size:14px;
  font-family: "Heebo";
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
}

.login-btn:hover{
  color:rgba(255,255,255, 0.85);
}

.text-reset{
  text-decoration: underline;
}

.error-message{
  color: #e7615a;
  width: 300px;
}

.card-body {
  padding: 15% !important;
}

.main-title {
  font-family: 'Heebo', sans-serif;
  font-size: 24px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #424242;
  margin-bottom: 20px !important;
}


</style>
