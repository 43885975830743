<template>
  <div class="bg-white">
    <HeaderMenu />
    <div class="container-fluid container h-full">
      <!-- Account Section -->
      <div class="col top-title">
        <h1>My Account</h1>
      </div>
      <div
          v-if="loading"
          class="w-100 d-flex justify-content-center align-items-center"
          style="height: 50vh"
      >
        <div class="loader" style="height: 75px; width: 75px"></div>
      </div>
      <div v-else class="col mb-2 row">
        <div class="user-information col-lg-11">
          <div class="row d-flex justify-content-sm-between mt-5 mb-2">
            <div class="contact-section col-lg-5" >
              <span>Contact </span>
              <form>
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="Full Name" v-model="profile.name" />
                </div>
                <div class="form-group">
                  <input class="form-control" disabled type="email" placeholder="Email" v-model="profile.email"/>
                </div>
                <div class="form-group">
                  <input class="form-control" type="tel" name="phone" placeholder="Phone" v-validate="'required|numeric|min:10'" v-model="profile.phone" />
                  <span class="help is-danger">{{ errors.first('phone') }}</span>
                </div>
                <div class="form-group">
                  <input class="form-control" type="password" placeholder="********" disabled />
                  <router-link to="/change-password">
                    <span class="text-underline change-password mt-2" style="float: right;">Change Password</span>
                  </router-link>
                </div>
              </form>
            </div>
            <div class="shipping-section col-lg-5">
              <span>Shipping Address</span>
              <form>
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="Street Address" v-model="profile.street_address"/>
                </div>
                <div class="row justify-content-between" >
                  <div class="form-group col-md-6">
                    <input class="form-control" type="text" placeholder="City" v-model="profile.city"/>
                  </div>
                  <div class="form-group col-md-6">
                    <input class="form-control" type="text" placeholder="Zip Code" v-model="profile.zip_code"/>
                  </div>
                </div>
                <div class="form-group">
                  <input class="form-control" type="text" placeholder="Country"  v-model="profile.country"/>
                </div>
              </form>
            </div>
          </div>

          <div class="text-right">
            <div class="text-danger">{{errorMessageProfile || " "}}</div>
            <button class="btn save-button" @click="updateProfile">
              <div v-if="updateProfileLoading" class="d-flex flex-row justify-content-center">
                <div class="loader"></div>
              </div>
              <div v-else>Save Changes</div>
            </button>
          </div>
        </div>

        <div class="order-history col-lg-12">
          <div>
            <h1 class="top-title">Order History</h1>
          </div>
          <div v-if="orders" class="table-responsive table-hover">
            <table class="table mt-4">
              <thead style="border-radius: 4px; background-color: #deeddf;">
              <tr class="header">
                <th>#</th>
                <th>Ordered On</th>
                <th>Products</th>
                <th>Ships to</th>
                <th>Total</th>
                <th></th>
              </tr>
              </thead>
              <tbody >
              <tr class="bg-white cursor-pointer" v-for="(order,index) in orders" :key="order.id">
                <td>{{index +1}}</td>
                <td><b>
                  {{order.created_at | moment }}
                  <br>
                  <small class="text-muted">Tracking # {{order.tracking_id}}</small>

                  </b></td>
                <td>
                  <div v-for="product in order.orderItems" :key="product.id">
                    <div v-if="product.product">
                      {{product.quantity + ' x ' + product.product.name || '-'}}
                    </div>
                  </div>
                </td>
                <td>
                  {{shippingDetails.address | truncate(50,'...')}}<br>
                  {{shippingDetails.city}}, {{' '+shippingDetails.country}} , {{' '+shippingDetails.postcode}}</td>
                <td>{{getTotal(order) || '-'}}</td>
                <td><button class="btn order-btn" @click="getOrderAgain(order.id)">order again</button></td>
              </tr >
              </tbody>
            </table>
          </div>
          <div v-else class="py-5">
            <div class="w-100 d-flex align-items-center justify-content-center flex-column">
              <img src="../../public/img/icons/eye-closed.svg" alt="">
              <h3>
                Nothing to show here!
              </h3>
              <p>
                There are no orders made so far.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-6"></div>
        <div class="col-6 offset-6">
          <div style="cursor: pointer; float:right !important;">
            <button @click="logout" style="background-color: white; width: 125px;
            border: 1px solid black;
            border-radius: 4px;
            margin-left: 20px;
            height: 40px;
            font-size: 14px;
            font-weight: bold;
            text-align: center;">LOGOUT</button>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import moment from 'moment';
import HeaderMenu from "../views/HeaderMenu";
import Footer from "../components/Footer";
import axios from "axios";
import router from "@/router";

export default {
  components: {
    HeaderMenu,
    Footer,
  },
  name: "AccountProfile",
  data() {
    return {
      loading: true,
      updateProfileLoading:false,
      profile: {
        name: "",
        email: "",
        phone: "",
        street_address: "",
        city: "",
        zip_code: "",
        country: "",
      },
      errorMessageProfile:"",
      orders: [],
      total: 0,
      shippingDetails: {},
    };
  },
  filters: {
    moment: function (date) {
      let d = moment(date);
      return d.isValid() ? moment(date).format("MMM D, YYYY") : "N/A";
    },
  },
  methods: {
    logout(){
      axios(
          {
            method: "GET",
            "url": "user/logout",
            "headers": {
              "content-type": "multipart/form-data"
            },
          }).then(result => {
        result;
        localStorage.removeItem('user');
        localStorage.removeItem('auth');
        localStorage.removeItem('access_token');
        localStorage.removeItem('access_token_expires_at');
        localStorage.removeItem('name');
        localStorage.removeItem('email');
        localStorage.removeItem('user_id');
        localStorage.removeItem('shippingDetails');
        router.push({ path: "/login" });
      }, error => {
        // eslint-disable-next-line no-console
        console.log(error.response);
      });
    },

    getProfile: function () {
      let url = `user/profile?access_token=${localStorage.getItem("access_token")}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              this.profile = result.data.user;
              this.orders = result.data.orders;
            }
          },
      ).catch((error) => {
        console.log(error);
        if (error.response.status === 401) {
          this.$router.push("/login")
        }
      });
      this.loading = false;
    },
    updateProfile: function () {
      this.$validator.validate().then((res) => {
        console.log(res)
       if (res){
         let url = `user/update-profile`;
         const profileData = {
           name: this.profile.name,
           email: this.profile.email,
           phone: this.profile.phone,
           city: this.profile.city,
           zip_code: this.profile.zip_code,
           country: this.profile.country,
           street_address: this.profile.street_address,
         }
         this.updateProfileLoading = true;
         axios({
           method: "POST",
           url: url,
           data: profileData,
           headers: {
             "content-type": "application/json",
           },
         }).then(
             (result) => {
               if (result) {
                 this.getProfile();
                 this.updateProfileLoading = false;
                 this.errorMessageProfile = "";
               }
             }
         ).catch((error) => {
           console.log(error);
           this.errorMessageProfile = error.response.data.message;
           this.updateProfileLoading = false;
         });
       }
      }).catch((err) => {
        console.log(err)
      });
    },
    getShippingDetails: function () {
      let url = `user/cart/get-shipping-details?access_token=${localStorage.getItem(
          "access_token"
      )}`;
      axios({
        method: "GET",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              if (result.data.shipping) {
                this.shippingDetails = result.data.shipping;
              }
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    getOrderAgain: function (id) {
      let url = `user/order/again/${id}?access_token=${localStorage.getItem(
          "access_token"
      )}`;
      axios({
        method: "POST",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
          (result) => {
            if (result) {
              if (result.data.order) {
                let items = [];
                result.data.order.orderItems.forEach(item=>{
                  item.name = item.product.name;
                  item.id = item.product.id;
                  item.image = item.product.image;
                  items.push(item);
                })
                localStorage.setItem('cart', JSON.stringify(items))
                this.$router.push('/checkout');
              }
            }
          },
          (error) => {
            console.log(error);
          }
      );
    },
    getTotal: function(order){
      let total = 0;
      order.orderItems.forEach(item => {
        total = total + parseFloat(item.price);
      });
      return total.toFixed(2);
    },
  },
  mounted() {
    this.getProfile();
    this.getShippingDetails();
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 1300px;
}

.top-title {
  padding-top: 50px;
  h1 {
    font-family: "Heebo", sans-serif;
    font-size: 24px;
    font-weight: bold;
    color: #424242 !important;
  }
}

.save-button {
  width: 135px;
  padding: 10px;
  background-color: #e7615a;
  color: white;
  text-transform: capitalize;
}

.contact-section, .shipping-section {
  span {
    font-size: 16px;
    font-weight: bold;
    color: #424242;
    margin-bottom: 20px;
  }
}





.order-btn {
  font-size: 14px !important;
  width: 135px;
  padding: 10px;
  font-size: 10px;
  background-color: #e7615a;
  color: white;
  text-transform: capitalize;
}

table, th, tr, td {
  color: #424242 !important;
  font-size: 14px;
}

thead {
  font-weight: bolder;
}

h1 {
  color: #424242 !important;
}

.change-password{
  font-size: 14px;
  line-height: 1.5;
  color: #424242;
}
.is-danger{
  color: red !important;
  font-weight: normal !important;
}
</style>
