<template>
    <div>
     <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="86px" height="86px" viewBox="0 0 86 86" version="1.1">
    <!-- Generator: sketchtool 61.2 (101010) - https://sketch.com -->
    <title>696E375C-60E7-4E12-8971-BA7D556F9339</title>
    <desc>Created with sketchtool.</desc>
    <g id="Pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Homepage-v.2" transform="translate(-981.000000, -2376.000000)">
            <g id="globe-copy" transform="translate(963.000000, 2359.000000)">
                <rect id="Rectangle-Copy-38" transform="translate(59.000000, 59.000000) scale(-1, 1) translate(-59.000000, -59.000000) " x="0" y="0" width="118" height="118"/>
                <circle id="oval" stroke="#143F49" stroke-width="5.5" cx="61.4081633" cy="60.2040816" r="39.7346939"/>
                <path d="M73.2139884,22.877551 C67.3409394,26.6532966 62.4885486,31.479603 62.4885486,35.4281389 C62.4885486,41.3509426 55.6436391,35.7224397 51.002821,38.5366911 C46.3620029,41.3509426 62.4885486,38.79354 62.4885486,46.8724271 C62.4885486,54.9513143 70.2962624,38.79354 74.2867207,46.8724271 C78.277179,54.9513143 65.4664769,63.8472077 60.0982779,59.399261 C54.7300789,54.9513143 46.137858,53.4637356 41.8298611,59.399261 C37.5218643,65.3347864 39.5170934,75.0818285 51.002821,78.9880029 C58.6599728,81.5921191 62.4885486,89.1797181 62.4885486,97.5306122 M80.6884346,91.5346084 C76.9028764,80.2811926 76.9028764,71.5605956 80.6884346,67.6691269 C86.3667719,61.8319239 80.6884346,54.9513143 86.9169457,54.9513143 C91.6576499,54.9513143 94.4705273,58.3313668 98.7346939,62.7349644" id="lines" stroke="#143F49" stroke-width="5.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
        </g>
    </g>
</svg>
    </div>
</template>

<script>
    export default {
        name: 'books'
    }
</script>

<style lang="scss" scoped>

</style>