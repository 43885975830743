<template>
  <div>
    <br>
    <div class="container-fluid mt--10">
      <div class="row">
        <div class="col-6 mb-5 mb-xl-0 mt--10">
          <div class="pt-4 pr-4 pl-5 pb-2 mt-3">
            Shipping   >   <b> Payment </b>   >   Summary
          </div>
          <div class="pl-lg-4">
            <div class="row">
              <div class="col-lg-8">
                <div class="col-lg-12">
                  <base-input
                    alternative=""
                    placeholder="Card Number"
                    input-classes="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      placeholder="Exp. Date"
                      input-classes="form-control"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                      alternative=""
                      placeholder="CVV"
                      input-classes="form-control"
                    />
                  </div>
                </div>
                <div class="col-lg-12">
                  <base-input
                    alternative=""
                    placeholder="Card Holder's Name"
                    input-classes="form-control"
                    v-model="shippingDetails.country"
                  />
                </div>
              </div>
            </div>
          </div>
        <div class="pl-5 row">
            <input type="checkbox" class="mt-1 mr-2">
             Back
            <div class="offset-5 mt-4"><img src="../../public/img/icons/continue.svg" alt=""></div>
        </div>
        </div>
        <div class="col-6 mb-5 mb-xl-0 mt--10">
          <div class="pl-lg-4">
              <h2 style="text-align:center !important">Order</h2>
            <div class="order">
              <div v-for="item in cart_items" :key="item.id">
                <div class="row col-12 pt-5 pl-5 pr-5 pb-1">
                  <div class="col-4">
                    <img :src="appURL + item.product.image" alt="" height="100" width="100">
                  </div>
                  <div class="col-4">
                    <h2>{{item.product.name}}</h2>
                    <h4>{{'€ ' + item.product.price  }}</h4>
                    <div class="row"><span class="mt-2 ml-3 mr-2">Quantity:</span> <input type="text" class="form-control quantity" placeholder="10" v-model="item.quantity"> </div>
                  
                  </div>
                  <div class="col-4 mt-3">
                    <h2>{{parseInt(item.product.price)* parseInt(item.quantity) || 0}}</h2>
                  </div>
                </div>
                <hr>
              </div>
              <div class="offset-5">
                <h3>Subtotal: {{total}}</h3>
                <h3>Tax: {{tax}}</h3>
                <h3>Shipping Charges: {{shipping_charges}}</h3>
              </div>
              <hr>
              <div class="offset-5">
                <h2>Subtotal: {{parseInt(total) + parseInt(tax) + parseInt(shipping_charges) || 0}}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "Product",
  components: {},
  data: () => {
    return {
      appURL: process.env.VUE_APP_MAIN_URL,
      profile: [],
      orders: [],
      cart_items: [],
      total: 0,
      tax: 25,
      shipping_charges: 50,
      shippingDetails: {},
    };
  },
  methods: {
    getProfile: function () {
      let url = `user/cart?id=${localStorage.getItem('user_id')}`;
      axios({
        method: "POST",
        url: url,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            this.cart_items = result.data.cart_items;
            this.cart_items.forEach(item => {
                this.total = this.total + parseInt(item.quantity) * parseInt(item.product.price);
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    addShippingDetails: function () {
      let url = `user/cart/add-shipping-details?id=${localStorage.getItem('user_id')}`;
      axios({
        method: "POST",
        url: url,
        data: this.shippingDetails,
        headers: {
          "content-type": "application/json",
        },
      }).then(
        (result) => {
          if (result) {
            result;
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
  mounted() {
    this.getProfile();
  },
};
</script>
<style>
.title{
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #424242;
  letter-spacing: normal;
}
.header{
  padding: 8px 260px 6px 30px;
  border-radius: 4px;
  background-color: #deeddf;
}



.info{
      font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.order{
  min-height: 700px;
}

.quantity{
    max-width: 50px !important;
    max-height: 40px !important;
}

.banner{
  border-radius: 4px;
  background-color: #457b80;
  min-height: 100px;
  padding: 20px;
}

.banner-text{
  font-family: Heebo;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
}
</style>