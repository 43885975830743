<template>
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="card bg-white shadow border-0">
                    <div class="card-body">
                        <form role="form">
                            <p class="main-title ">Sign Up</p>
                            <div class="email-input-group mx-auto input-group-alternative">
                                <input
                                name="email"
                                type="email"
                                id="inputEmail"
                                class="form-control border-silver "
                                v-validate="'required|email'"
                                placeholder="Email address"
                                required=""
                                v-model="email"
                                autofocus=""
                                :class="errors.has('email')? 'is-invalid' : ''"
                                />
                            </div>
                            <span v-show="errors.has('email')" class="help is-danger">{{ errors.first('email') }}</span>
                          <div
                              class="password-input-group input-group input-group-alternative mx-auto"
                          >
                            <input class="form-control" :type="inputType" id="password"
                                   placeholder="Password"
                                   addon-right-icon="fa fa-eye"
                                   v-model="password"
                                   required=""
                                   autofocus=""
                                   name="password"
                                   v-validate="'required|min:6'"
                                  :class="errors.has('password')? 'is-invalid' : ''"
                                   @keyup.enter="register"
                            >
                            <div class="input-group-prepend m-2">
                              <span v-if="!passwordShow" class="eye-icon cursor-pointer" @click="hidePassword"><i class="fa fa-eye" aria-hidden="true"></i></span>
                              <span v-else class="eye-icon cursor-pointer" @click="showPassword"><i class="fas fa-eye-slash"></i></span>
                            </div>
                          </div>
                            <span v-show="errors.has('password')" class="help is-danger">{{ errors.first('password') }}</span>
                            <div v-if="errorMessage" class="mt-3 help is-danger">
                                {{errorMessage}}
                            </div>
                            <div class="text-center mb-4" style="margin-top: 33px;">
                                <div class="btn btn-link create-account-btn mx-auto" @click="register">
                                    <div class="loader mx-auto" v-if="loading"></div>
                                    <div v-else>CREATE ACCOUNT</div>
                                </div>
                            </div>
                        </form>
                        <div class="have-account text-center mx-auto">
                            Already have an account? <span class="sign-in"><router-link to="/login" class="text-reset">Sign in!</router-link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
  import axios from "axios";

  export default {
    name: 'login',
    data() {
      return {
          email: '',
          password: '',
          passwordShow: false,
          inputType: 'password',
          loading: false,
          errorMessage: "",
          passwordError: false,
      }
    },
      methods:{
          register: function () {
              this.$validator.validateAll().then((result) => {
                if (result) {
                  this.loading = true;
                  axios({
                    method: "POST",
                    url: `user/register`,
                    data: {
                      'email': this.email,
                      'password': this.password
                    },
                    headers: {
                      "content-type": "application/json",
                    }
                  }).then(
                      result => {
                        if (result) {
                          this.$logEvent(this.$analytics,"sign_up");
                          this.$logEvent(this.$analytics,"user_engagement");
                          this.loading = false;
                          localStorage.setItem('confirm_email_message', 'We have sent you an email confirmation');
                          this.$noty.success('Confirmation email sent to your email address.',{ closeWith : ['button']});
                          location.assign('/login');
                        }
                      },
                      error => {
                        this.loading = false;
                        this.errorMessage = error.response.data.message;
                        console.log(error.response);
                      });
                }
              }).catch(() => {});
          },
          showPassword: function(){
            this.passwordShow = false;
            this.inputType = 'password';
          },
          hidePassword: function(){
            this.passwordShow = true;
            this.inputType = 'text';
          },
      },

    beforeCreate() {
      localStorage.setItem('search', '')
    },
  }
</script>
<style>
.card{
    border-radius: 16px;
}

.have-account {
  /* width: 227px;
  height: 22px;
  margin: 52px 36px 0 29px; */
  margin-top: 52px;
  font-family: Heebo;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #424242;
}

.forgot{
  font-family: Heebo;
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #424242 !important;
}

.sign-in{
  color: #e7615a;
}
.input-group-alternative{
  box-shadow: inset 0 1px 6px 0 rgba(185, 195, 188, 0.4) !important;
}

.form-label-group {
  position: relative;
  margin-bottom: 0px;;
}

.form-label-group input::-webkit-input-placeholder{
  color: #b9c3bc !important;
}

.form-control::placeholder {
  color: red;
}

.input-group-text.border-left-0.rounded-0.rounded-right{
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: solid 1px #b9c3bc;
  background: transparent !important;
}

.fixed-width{
    width:292px;
    margin-left:auto;
    margin-right:auto;
}

h1{
  color: #424242;
}

.text-reset{
  text-decoration: underline;
}

.create-account-btn{
  height: 40px;
  width: 300px;
  border-radius: 4px;
  box-shadow: 2px 2px 8px -2px rgba(196, 82, 77, 0.4);
  background-color: #e7615a;

  font-size:14px;
  font-family: "Heebo";
  font-weight: bold;
  color: #fff;
  letter-spacing: normal;
}

.create-account-btn:hover{
  color:rgba(255,255,255, 0.85);
}

.password-input-group{
    margin-top:16px;
}

.input-group-text.border-left-0.rounded-0.rounded-right{
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border: solid 1px #b9c3bc;
  background: transparent !important;
}

.form-control{
  background: transparent !important;
}

.card-body {
  padding: 15% !important;
}

.main-title {
  font-family: 'Heebo', sans-serif;
  font-size: 24px !important;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #424242;
  margin-bottom: 20px !important;
}

</style>
