<template>
  <div>
    
    <HeaderMenu />

    <!-- start of body content -->

    <div class="container">
      <div class="row">
        <div class="col-md-12 ">
          <p class="head-title">
            <span style="font-size: 24pt;"
              ><strong>PLASTIC FREE EUROPE COOKIE POLICY</strong></span
            >
          </p>
          <hr />
          <p class="title">
            <span style="font-size: 16pt;"><strong>INTRODUCTION</strong></span>
          </p>
          <p>
            Plastic Free Europe, may use cookies, web beacons, tracking pixels,
            and other tracking technologies when you visit our website
            (plasticfreeeurope.org), including any other media form, media
            channel, mobile website, or mobile application related or connected
            thereto (collectively, the “Site”) to help customize the Site and
            improve your experience.
          </p>
          <p>
            We reserve the right to make changes to this Cookie Policy at any
            time and for any reason. We will alert you about any changes by
            updating the “Last Updated” date of this Cookie Policy. Any changes
            or modifications will be effective immediately upon posting the
            updated Cookie Policy on the Site, and you waive the right to
            receive specific notice of each such change or modification.
          </p>
          <p>
            You are encouraged to periodically review this Cookie Policy to stay
            informed of updates. You will be deemed to have been made aware of,
            will be subject to, and will be deemed to have accepted the changes
            in any revised Cookie Policy by your continued use of the Site after
            the date such revised Cookie Policy is posted.
          </p>
          <p class="title">
            <span style="font-size: 16pt;"
              ><strong>USE OF COOKIES</strong></span
            >
          </p>
          <p>
            A “cookie” is a string of information which assigns you a unique
            identifier that we store on your computer. Your browser then
            provides that unique identifier to use each time you submit a query
            to the Site. We use cookies on the Site to, among other things, keep
            track of services you have used, record registration information,
            record your user preferences, keep you logged into the Site,
            facilitate purchase procedures, and track the pages you visit.
            Cookies help us understand how the Site is being used and improve
            your user experience.
          </p>
          <p class="title">
            <span style="font-size: 16pt;"
              ><strong>TYPES OF COOKIES</strong></span
            >
          </p>
          <p>
            The following types of cookies may be used when you visit the Site:
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"
              ><strong>Advertising Cookies</strong></span
            >
          </p>
          <p>
            Advertising cookies are placed on your computer by advertisers and
            ad servers in order to display advertisements that are most likely
            to be of interest to you. These cookies allow advertisers and ad
            servers to gather information about your visits to the Site and
            other websites, alternate the ads sent to a specific computer, and
            track how often an ad has been viewed and by whom. These cookies are
            linked to a computer and do not gather any personal information
            about you.
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"
              ><strong>Analytics Cookies</strong></span
            >
          </p>
          <p>
            Analytics cookies monitor how users reached the Site, and how they
            interact with and move around once on the Site. These cookies let us
            know what features on the Site are working the best and what
            features on the Site can be improved.
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"><strong>Our Cookies</strong></span>
          </p>
          <p>
            Our cookies are “first-party cookies”, and can be either permanent
            or temporary. These are necessary cookies, without which the Site
            won’t work properly or be able to provide certain features and
            functionalities. Some of these may be manually disabled in your
            browser, but may affect the functionality of the Site.
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"
              ><strong>Personalization Cookies</strong></span
            >
          </p>
          <p>
            Personalization cookies are used to recognize repeat visitors to the
            Site. We use these cookies to record your browsing history, the
            pages you have visited, and your settings and preferences each time
            you visit the Site.
          </p>
          <p class="p-title"><strong>Security Cookies</strong></p>
          <p>
            Security cookies help identify and prevent security risks. We use
            these cookies to authenticate users and protect user data from
            unauthorized parties.
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"
              ><strong>Site Management Cookies</strong></span
            >
          </p>
          <p>
            Site management cookies are used to maintain your identity or
            session on the Site so that you are not logged off unexpectedly, and
            any information you enter is retained from page to page. These
            cookies cannot be turned off individually, but you can disable all
            cookies in your browser.
          </p>
          <p class="p-title">
            <span style="font-size: 14pt;"
              ><strong>Third-Party Cookies</strong></span
            >
          </p>
          <p>
            Third-party cookies may be place on your computer when you visit the
            Site by companies that run certain services we offer. These cookies
            allow the third parties to gather and track certain information
            about you. These cookies can be manually disabled in your browser.
          </p>
          <p class="p-title">
            <span style="font-size: 16pt;"
              ><strong>CONTROL OF COOKIES</strong></span
            >
          </p>
          <p>
            Most browsers are set to accept cookies by default. However, you can
            remove or reject cookies in your browser’s settings. Please be aware
            that such action could affect the availability and functionality of
            the Site.
          </p>
          <p>
            For more information on how to control cookies, check your browser
            or device’s settings for how you can control or reject cookies, or
            visit the following links:
          </p>
          <p>
            <a href="https://support.apple.com/kb/ph19214?locale=en_US"
              >Apple Safari</a
            >
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=en"
              >Google Chrome</a
            >
          </p>
          <p>
            <a
              href="https://privacy.microsoft.com/en-us/windows-10-microsoft-edge-and-privacy"
              >Microsoft Edge</a
            >
          </p>
          <p>
            <a
              href="https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies"
              >Microsoft Internet Explorer</a
            >
          </p>
          <p>
            <a
              href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
              >Mozilla Firefox</a
            >
          </p>
          <p>
            <a href="http://www.opera.com/help/tutorials/security/cookies/"
              >Opera</a
            >
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en&amp;oco=1"
              >Android (Chrome)</a
            >
          </p>
          <p>
            <a
              href="https://help.blackberry.com/en/blackberry-classic/10.3.1/help/mwa1334238823957.html"
              >Blackberry</a
            >
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DiOS&amp;hl=en&amp;oco=1"
              >Iphone or Ipad (Chrome)</a
            >
          </p>
          <p>
            <a
              href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DAndroid&amp;hl=en&amp;oco=1"
              >Iphone or Ipad (Safari)</a
            >
          </p>
          <p>
            In addition, you may opt-out of some third-party cookies through the
            <a href="http://optout.networkadvertising.org/#!/"
              >Network Advertising Initiative’s Opt-Out Tool</a
            >.
          </p>
          <p class="p-title">
            <span style="font-size: 16pt;"
              ><strong>OTHER TRACKING TECHNOLOGIES</strong></span
            >
          </p>
          <p>
            In addition to cookies, we may use web beacons, pixel tags, and
            other tracking technologies on the Site to help customize the Site
            and improve your experience. A “web beacon” or “pixel tag” is tiny
            object or image embedded in a web page or email. They are used to
            track the number of users who have visited particular pages and
            viewed emails, and acquire other statistical data. They collect only
            a limited set of data, such as a cookie number, time and date of
            page or email view, and a description of the page or email on which
            they reside. Web beacons and pixel tags cannot be declined. However,
            you can limit their use by controlling the cookies that interact
            with them.
          </p>
          <p class="p-title">
            <span style="font-size: 16pt;"
              ><strong>PRIVACY POLICY</strong></span
            >
          </p>
          <p>
            For more information about how we use information collected by
            cookies and other tracking technologies, please refer to our Privacy
            Policy posted on the Site. This Cookie Policy is part of and is
            incorporated into our Privacy Policy. By using the Site, you agree
            to be bound by this Cookie Policy and our Privacy Policy.
          </p>
          <p class="p-title">
            <span style="font-size: 16pt;"><strong>CONTACT US</strong></span>
          </p>
          <p>
            If you have questions or comments about this Cookie Policy, please
            contact us at:
          </p>
          <p>Plastic Free Europe</p>
          <p>Stockholm</p>
          <p>
            <a href="mailto:contact@plasticfreeeurope.org"
              >contact@plasticfreeeurope.org</a
            >
          </p>
        </div>
      </div>
    </div>
    <Footer/>

  </div>

    <!-- end of body content -->
    
</template>

<script>
import HeaderMenu from "../views/HeaderMenu";
import Footer from "../components/Footer.vue";

export default {
  components: {HeaderMenu , Footer},
  name: "cookies",
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Heebo&display=swap");

p {
  font-family: "Heebo", sans-serif;
  color: #424242;
  font-weight: regular;
}

.collection {
  font-size: 16pt;
  font-weight: 700;
}

.head-title {
  //background-color: black;

  font-size: 24pt;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
}

.title {
  //background-color: black;
  font-size: 16pt;
  font-weight: 700;
  font-family: "Heebo", sans-serif;
}
h2 {
  font-family: "Heebo", sans-serif;
  font-weight: 700;
}

.p-title {
  font-weight: 700;
}







</style>
